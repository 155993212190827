import {
  Button,
  Grid,
  FormControlLabel,
  Switch,
  DialogContent,
  DialogContentText,
  DialogActions as MuiDialogActions,
  Typography,
  MenuItem,
  ListItemIcon,
  Radio,
  Paper,
  InputAdornment,
  ListItemText
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Field,
  reduxForm,
  InjectedFormProps,
  formValueSelector,
  change,
  WrappedFieldInputProps
} from 'redux-form'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import CheckboxBlankCircleIcon from 'mdi-react/CheckboxBlankCircleIcon'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PlaylistPlusIcon from 'mdi-react/PlaylistPlusIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import { TFunction } from 'i18next'
import CalendarEditIcon from 'mdi-react/CalendarEditIcon'
import {
  closeDialog as closeDialogAction,
  openDialog as openDialogAction
} from '../../../actions/dialogs'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import {
  ReduxDateTimePicker,
  ReduxSelectWithAutocomplete,
  ReduxTextField,
  ReduxTimePicker,
  ReduxCreatableAutocomplete,
  ReduxRadioGroup
} from '../../../components/FormInput/ReduxWrappers'
import {
  selectPlaylistsAsOptions,
  selectPlaylistsByAccessAsArray
} from '../../../selectors/playlists'
import i18n from '../../../translations/i18n'
import { CalendarEvent, RecurringRuleFormValue } from '../../../types/channels'
import { compareStrings } from '../../../utils/sorting'
import { required, defaultInterval } from '../../../validation'
import Dialog from '../../Dialog'
import { getPriorityOptions } from '../../../utils/channels'
import SimpleExtensionPanel from '../../../components/ExtensionPanels/SimpleExtensionPanel'
import { bindSubmitActionToPromise, parseNumberField } from '../../../utils/forms'
import {
  selectCurrentEnvironmentPermissionTagsAsOptions,
  selectCurrentEnvironmentTagsAsOptions,
  selectEnvironmentFeaturesChannelsImportScreenCodes
} from '../../../selectors/environments'
import { selectContentIdFromPathname } from '../../../selectors/routing'
import { selectChannelById } from '../../../selectors/channels'
import ReduxSelectScreens from '../../../components/FormInput/ReduxWrappers/ReduxSelectScreens'
import {
  deleteChannelItem as deleteChannelItemAction,
  createChannelItem as createChannelItemAction,
  updateChannelItem as updateChannelItemAction
} from '../../../actions/channels'
import RecurringScheduleField from './RecurringScheduleField'

const priorities = getPriorityOptions()

const styles = () => ({
  priorityIcon: {
    position: 'relative' as 'relative',
    top: 3
  },
  listItemText: {
    display: 'inline-flex'
  }
})

const useStyles = makeStyles(styles)

const RecurringEventSwitch = ({
  input: { onChange, value: fieldValue },
  label,
  disabled,
  clearRecurrancyRules
}: {
  input: WrappedFieldInputProps
  label: string
  disabled?: boolean
  clearRecurrancyRules: () => void
}) => (
  <FormControlLabel
    control={
      <Switch
        disabled={disabled}
        checked={fieldValue ? fieldValue : false}
        onChange={(event, value) => {
          if (fieldValue) {
            clearRecurrancyRules()
          }
          onChange(value)
        }}
        color='primary'
      />
    }
    label={label}
  />
)

const playlistSettingsFields = ({
  t,
  disableField,
  permissionTagsAsOptions
}: {
  t: TFunction
  disableField?: boolean
  permissionTagsAsOptions: AutocompleteOption[]
}) => (
  <Fragment>
    <Grid item xs={8}>
      <Field
        disabled={disableField}
        name='playlist.permissionTags'
        label={t('playlists.forms.permissionTags')}
        helperText={t('playlists.forms.permissionTagHelper')}
        options={permissionTagsAsOptions}
        component={ReduxCreatableAutocomplete}
        multiple
      />
    </Grid>
    <Grid item xs={4}>
      <Field
        fullWidth
        variant='outlined'
        disabled={disableField}
        name='playlist.defaultInterval'
        label={t('playlists.forms.defaultInterval')}
        helperText={t('playlists.forms.defaultIntervalHelper')}
        type='number'
        parse={parseNumberField}
        component={ReduxTextField}
        InputProps={{
          endAdornment: <InputAdornment position='end'>s</InputAdornment>
        }}
        required
        validate={[required, defaultInterval]}
      />
    </Grid>
  </Fragment>
)

interface OwnProps {
  submitButtonLabel: string
  /** name of the form  */
  form: 'CreateChannelItemForm' | 'UpdateChannelItemForm'
  dialogId?: string
  isUpdate?: boolean
  event?: CalendarEvent
  /** If user has no access to playlist, disable fields */
  userHasAccess?: boolean
}

type ComponentProps = OwnProps
type CreateChannelItemFormProps = ComponentProps

/**
 * Form used to create or update channel item
 */
const CreateChannelItemForm: React.FC<CreateChannelItemFormProps &
  InjectedFormProps<{}, ComponentProps>> = ({
  submitButtonLabel,
  handleSubmit,
  submitting,
  error,
  dialogId,
  form,
  isUpdate,
  userHasAccess,
  event
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const isImportScreenCodesEnabled = useSelector(selectEnvironmentFeaturesChannelsImportScreenCodes)
  const channelId = useSelector(selectContentIdFromPathname)
  const channel = useSelector(selectChannelById(channelId))
  const isSyncPlay = channel?.syncPlay ? true : false
  const allPlaylists = useSelector(selectPlaylistsByAccessAsArray)
  // if channel has sync play property allow only sync playlists to channel
  const playlists = (isSyncPlay
    ? allPlaylists.filter(({ syncPlay }) => syncPlay)
    : allPlaylists
  ).sort(compareStrings('name'))

  const tagOptions = useSelector(selectCurrentEnvironmentTagsAsOptions)
  const permissionTagsAsOptions = useSelector(selectCurrentEnvironmentPermissionTagsAsOptions)

  // form value selectors
  const recurringRuleValue: RecurringRuleFormValue | undefined = useSelector(state =>
    formValueSelector(form)(state, 'channelItem.recurringRule')
  )
  const isRecurringEvent: boolean | undefined = useSelector(state =>
    formValueSelector(form)(state, 'channelItem.recurringEvent')
  )
  const tagsSelected: boolean =
    useSelector(state => formValueSelector(form)(state, 'channelItem.conditions.tags'))?.length > 0
  const isNewPlaylist: boolean | undefined = useSelector(state =>
    formValueSelector(form)(state, 'isNewPlaylist')
  )
  const selectedPlaylistId: AutocompleteOption | undefined = useSelector(state =>
    formValueSelector(form)(state, 'channelItem.playlistId')
  )

  // dispatch functions
  const openDialog = (dialogId: string) => dispatch(openDialogAction(dialogId))
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))
  const deleteChannelItem = (itemId: string) => dispatch(deleteChannelItemAction(itemId))
  const createChannelItem = bindSubmitActionToPromise(dispatch, createChannelItemAction)
  const updateChannelItem = bindSubmitActionToPromise(dispatch, updateChannelItemAction)

  const clearRecurrancyRules = () => dispatch(change(form, 'channelItem.recurringRule', null))
  const setIsNewPlaylist = (val: boolean) => dispatch(change(form, 'isNewPlaylist', val))

  const disableField = isUpdate && !userHasAccess
  return (
    <form onSubmit={handleSubmit(isUpdate ? updateChannelItem : createChannelItem)}>
      <Grid container spacing={2}>
        {!isUpdate && (
          <Grid item xs={12}>
            <Button
              onClick={() => setIsNewPlaylist(!isNewPlaylist)}
              variant='contained'
              color='primary'
              startIcon={isNewPlaylist ? <PlaylistPlayIcon /> : <PlaylistPlusIcon />}>
              {isNewPlaylist
                ? t('channels.forms.selectPlaylist')
                : t('channels.forms.createNewPlaylist')}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          {isNewPlaylist ? (
            <Paper style={{ padding: 8 }}>
              <Typography variant='body1'>
                {t(`playlists.actions.${isSyncPlay ? 'createNewSyncPlaylist' : 'createNew'}`)}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    disabled={disableField}
                    name='playlist.name'
                    label={t('playlists.actions.playlistName')}
                    component={ReduxTextField}
                    required
                    validate={[required]}
                  />
                </Grid>
                {playlistSettingsFields({ t, disableField, permissionTagsAsOptions })}
              </Grid>
            </Paper>
          ) : (
            <SimpleExtensionPanel
              bordered
              title={t('channels.forms.playlistSettings')}
              TitleIcon={SettingsIcon}
              isOpen={!isUpdate}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    variant='outlined'
                    disabled={disableField}
                    name='channelItem.playlistId'
                    placeholder={t(
                      `channels.forms.${isSyncPlay ? 'selectSyncPlaylist' : 'selectPlaylist'}`
                    )}
                    required
                    validate={[required]}
                    options={[...selectPlaylistsAsOptions(playlists)]}
                    component={ReduxSelectWithAutocomplete}
                    label={t(
                      `channels.forms.${isSyncPlay ? 'selectSyncPlaylist' : 'selectPlaylist'}`
                    )}
                    OptionIcon={PlaylistPlayIcon}
                  />
                </Grid>
                {selectedPlaylistId &&
                  isUpdate &&
                  playlistSettingsFields({ t, disableField, permissionTagsAsOptions })}
              </Grid>
            </SimpleExtensionPanel>
          )}
        </Grid>
        <Grid item xs={12}>
          <SimpleExtensionPanel
            bordered
            title={t('channels.forms.visibilitySettings')}
            TitleIcon={CalendarEditIcon}
            isOpen={false}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  variant='outlined'
                  disabled={disableField}
                  name='channelItem.start'
                  label={t('channels.forms.startDate')}
                  required
                  validate={[required]}
                  component={ReduxDateTimePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  fullWidth
                  variant='outlined'
                  disabled={disableField}
                  name='channelItem.end'
                  label={t('channels.forms.endDate')}
                  required
                  validate={[required]}
                  component={ReduxDateTimePicker}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant='outlined'
                  disabled={disableField}
                  name='channelItem.startTime'
                  label={t('date.startTime')}
                  component={ReduxTimePicker}
                  fullWidth
                  clearable
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  variant='outlined'
                  disabled={disableField}
                  name='channelItem.endTime'
                  label={t('date.endTime')}
                  component={ReduxTimePicker}
                  fullWidth
                  clearable
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='caption'>
                  {t('channels.forms.startAndEndTimeHelper')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  disabled={disableField}
                  label={t('channels.forms.priority')}
                  helperText={t('channels.forms.priorityHelper')}
                  name='channelItem.priority'
                  component={ReduxTextField}
                  fullWidth
                  select>
                  {priorities.map(({ label, color }) => (
                    <MenuItem key={label} value={label}>
                      <ListItemIcon className={classes.priorityIcon}>
                        <CheckboxBlankCircleIcon fontSize='small' color={color} />
                      </ListItemIcon>
                      <ListItemText
                        primary={t(`channels.forms.priorities.${label}`)}
                        className={classes.listItemText}
                      />
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              {isImportScreenCodesEnabled && (
                <Grid item xs={12}>
                  <Field
                    disabled={disableField}
                    name='channelItem.conditions.screens'
                    label={t('channels.forms.screens')}
                    helperText={t('channels.forms.channelItemScreensHelper')}
                    component={ReduxSelectScreens}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  disabled={disableField}
                  name='channelItem.conditions.tags'
                  label={t('screens.tags')}
                  helperText={t('channels.forms.channelItemTagsHelper')}
                  component={ReduxCreatableAutocomplete}
                  options={tagOptions}
                  multiple
                />
              </Grid>
              {tagsSelected && (
                <Grid item xs={12}>
                  <Field
                    name='channelItem.conditions.operator'
                    label={t('channels.forms.operator.require')}
                    component={ReduxRadioGroup}
                    style={{ display: 'flex', flexDirection: 'row' }}>
                    <FormControlLabel
                      value='or'
                      label={t('channels.forms.operator.some')}
                      control={<Radio color='primary' disabled={disableField} />}
                    />
                    <FormControlLabel
                      value='and'
                      label={t('channels.forms.operator.all')}
                      control={<Radio color='primary' disabled={disableField} />}
                    />
                  </Field>
                  <Typography display='block' variant='caption'>
                    {t('channels.forms.operator.helperText')}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Field
                  disabled={disableField}
                  name='channelItem.recurringEvent'
                  label={t('channels.recurrency.recurring')}
                  component={RecurringEventSwitch}
                  clearRecurrancyRules={clearRecurrancyRules}
                />
              </Grid>
              {isRecurringEvent && (
                <RecurringScheduleField disabled={disableField} ruleValue={recurringRuleValue} />
              )}
            </Grid>
          </SimpleExtensionPanel>
        </Grid>
        {isUpdate && event && (
          <Dialog
            buttonDisabled={disableField}
            dialogId='RemoveChannelItemDialog'
            title={`${t('channels.actions.deleteChannelItemTitle')}`}
            noOpenDialogButton
            tooltipTitle={t('channels.actions.deleteChannelItem')}
            Content={
              <Fragment>
                <DialogContent>
                  <DialogContentText>
                    {t('channels.actions.deleteItemConfirmation')}
                  </DialogContentText>
                </DialogContent>
                <MuiDialogActions>
                  <Button onClick={() => closeDialog(`RemoveChannelItemDialog`)} color='primary'>
                    {t('general.cancel')}
                  </Button>
                  <Button onClick={() => deleteChannelItem(event.itemId)} color='primary' autoFocus>
                    {t('general.delete')}
                  </Button>
                </MuiDialogActions>
              </Fragment>
            }
          />
        )}
        <Grid item xs={12}>
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <MuiDialogActions>
        {isUpdate && event && (
          <Button
            disabled={disableField}
            onClick={() =>
              recurringRuleValue
                ? openDialog(`RemoveChannelItemDialog`)
                : deleteChannelItem(event.itemId)
            }
            color='secondary'
            endIcon={<DeleteIcon />}>
            {t('channels.actions.deleteChannelItem')}
          </Button>
        )}
        {closeDialog && dialogId && (
          <Button onClick={() => closeDialog(dialogId)} color='primary'>
            {t('general.cancel')}
          </Button>
        )}
        <Button disabled={submitting || disableField} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </MuiDialogActions>
    </form>
  )
}
const validate = (values: any) => {
  const channelItem = values?.channelItem
  const startDate = channelItem?.startDate
  const endDate = channelItem?.endDate
  const startTime = channelItem?.startTime
  const endTime = channelItem?.endTime
  const errors: any = { channelItem: {} }
  if (startDate > endDate) {
    errors.channelItem.endDate = i18n.t('error.playlist.scheduleEndDate')
  }
  if (startTime > endTime) {
    errors.channelItem.startTime = i18n.t('error.playlist.scheduleEndTime')
  }
  if (startTime && !endTime) {
    errors.channelItem.endTime = i18n.t('validation.required')
  }
  if (endTime && !startTime) {
    errors.channelItem.startTime = i18n.t('validation.required')
  }
  return errors
}

export default reduxForm<{}, ComponentProps>({
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(CreateChannelItemForm)
