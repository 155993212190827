import { Grid, MenuItem, ListItemIcon, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { Fragment } from 'react'
import { Field, formValueSelector, FieldArray } from 'redux-form'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import TableColumnIcon from 'mdi-react/TableColumnIcon'
import {
  List,
  Channel,
  PlaylistUI,
  ChannelItemPriority,
  AutocompleteOption,
  ScreenType
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { useSelector } from 'react-redux'
import CheckboxBlankCircleIcon from 'mdi-react/CheckboxBlankCircleIcon'
import { selectPlaylistsAsOptions } from '../../../../selectors/playlists'
import {
  ReduxSelectWithAutocomplete,
  ReduxSwitch,
  ReduxTextField
} from '../../../../components/FormInput/ReduxWrappers'
import { selectChannelsAsScreenOptions } from '../../../../selectors/channels'
import { selectListsAsOptions } from '../../../../selectors/lists'
import colors from '../../../../styles/common/colors'
import { parseNumberField } from '../../../../utils/forms'
import { ScreenChannelOption } from '../../../../types/screens'
import SelectChannelsField from './SelectChannelsField'
import ActivateField from './ActivateField'

const priorities = [
  {
    label: ChannelItemPriority.low,
    color: colors.priorities.low
  },
  {
    label: ChannelItemPriority.medium,
    color: colors.priorities.medium
  }
]

const minItems = [...Array(20).keys()].splice(1)

const useStyles = makeStyles(() => ({
  priorityIcon: {
    position: 'relative' as 'relative',
    top: 3
  }
}))

export interface ContentSelectionFieldProps {
  type: ScreenType
  environmentId?: string
  playlists: PlaylistUI[]
  lists: List[]
  channels: Channel[]
  t: TFunction
  isSystemAdmin: boolean
  isAdmin: boolean
  isBatchUpdate?: boolean
}

const ContentSelectionField: React.FC<ContentSelectionFieldProps> = ({
  type,
  environmentId,
  playlists,
  lists,
  channels,
  t,
  isSystemAdmin,
  isAdmin,
  isBatchUpdate
}) => {
  const classes = useStyles()
  const selectedChannels: ScreenChannelOption[] | undefined = useSelector(state =>
    formValueSelector('CreateScreenForm')(state, 'channelIds')
  )
  const playlistId: AutocompleteOption | undefined = useSelector(state =>
    formValueSelector('CreateScreenForm')(state, 'playlistId')
  )
  const hasChannelAndPlaylist =
    selectedChannels && selectedChannels.length > 0 && playlistId && playlistId.value !== 'none'
  const selectedProperties = useSelector(state =>
    formValueSelector('BatchupdateScreensForm')(state, 'properties')
  )
  return (
    <Fragment>
      {type === ScreenType.media && (
        <Fragment>
          {isBatchUpdate && (
            <Grid item xs={1}>
              <ActivateField name='channelIds' />
            </Grid>
          )}
          <Grid item xs={isBatchUpdate ? 11 : 12}>
            <FieldArray
              name='channelIds'
              component={SelectChannelsField}
              props={{
                channelOptions: selectChannelsAsScreenOptions(channels, environmentId),
                disabled: isBatchUpdate && !selectedProperties?.channelIds
              }}
            />
          </Grid>
          {isBatchUpdate && (
            <Grid item xs={1}>
              <ActivateField name='playlistId' />
            </Grid>
          )}
          <Grid item xs={isBatchUpdate ? 11 : 12}>
            <Field
              name='playlistId'
              placeholder={t('screens.selectPlaylist')}
              label={t('screens.content.playlist')}
              disabled={isBatchUpdate && !selectedProperties?.playlistId}
              options={[
                {
                  value: 'none',
                  label: t('screens.noPlaylist')
                },
                ...selectPlaylistsAsOptions(
                  playlists.filter(({ integration }) => !integration),
                  environmentId
                )
              ]}
              component={ReduxSelectWithAutocomplete}
              OptionIcon={PlaylistPlayIcon}
            />
          </Grid>

          {hasChannelAndPlaylist && (
            <Fragment>
              {isBatchUpdate && (
                <Grid item xs={1}>
                  <ActivateField name='playlistPriority' />
                </Grid>
              )}
              <Grid item xs={isBatchUpdate ? 5 : 6}>
                <Field
                  label={t('screens.playlistPriority')}
                  name='playlistPriority'
                  component={ReduxTextField}
                  disabled={isBatchUpdate && !selectedProperties?.playlistPriority}
                  parse={parseNumberField}
                  fullWidth
                  select>
                  {priorities.map(({ label, color }) => (
                    <MenuItem key={label} value={label}>
                      <ListItemIcon className={classes.priorityIcon}>
                        <CheckboxBlankCircleIcon fontSize='small' color={color} />
                      </ListItemIcon>
                      <Typography variant='inherit'>
                        {t(`channels.forms.priorities.${label}`)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Fragment>
          )}
          {// minItems option is available only for 'media' type of screen.
          isSystemAdmin && (
            <Fragment>
              {isBatchUpdate && (
                <Grid item xs={1}>
                  <ActivateField name='minItems' />
                </Grid>
              )}
              <Grid item xs={isBatchUpdate ? 11 : 12}>
                <Field
                  name='minItems'
                  label={t('screens.createScreenForm.minItems')}
                  helperText={t('screens.createScreenForm.minItemsHelper')}
                  disabled={isBatchUpdate && !selectedProperties?.minItems}
                  component={ReduxSelectWithAutocomplete}
                  fullWidth
                  type='number'
                  options={minItems.map(value => ({ value, label: `${value}` }))}
                />
              </Grid>
            </Fragment>
          )}
          {// 'content.interleave' option is available only for 'media' type of screen.
          isAdmin && (
            <Fragment>
              {isBatchUpdate && (
                <Grid item xs={1}>
                  <ActivateField name='interleave' />
                </Grid>
              )}
              <Grid item xs={isBatchUpdate ? 11 : 12}>
                <Field
                  name='content.interleave'
                  component={ReduxSwitch}
                  label={t('screens.createScreenForm.interleaveContent')}
                  disabled={isBatchUpdate && !selectedProperties?.interleave}
                />
                <Typography display='block' variant='caption'>
                  {t('screens.createScreenForm.interleaveContentHelper')}
                </Typography>
              </Grid>
            </Fragment>
          )}
          {// 'content.ignoreTagConditions' option is available only for 'media' type of screen.
          isAdmin && (
            <Fragment>
              {isBatchUpdate && (
                <Grid item xs={1}>
                  <ActivateField name='ignoreTagConditions' />
                </Grid>
              )}
              <Grid item xs={isBatchUpdate ? 11 : 12}>
                <Field
                  name='content.ignoreTagConditions'
                  component={ReduxSwitch}
                  label={t('screens.createScreenForm.ignoreTagConditions')}
                  disabled={isBatchUpdate && !selectedProperties?.ignoreTagConditions}
                />
                <Typography display='block' variant='caption'>
                  {t('screens.createScreenForm.ignoreTagConditionsHelper')}
                </Typography>
              </Grid>
            </Fragment>
          )}
          {// 'content.ignoreScreensConditions' option is available only for 'media' type of screen.
          isAdmin && (
            <Fragment>
              {isBatchUpdate && (
                <Grid item xs={1}>
                  <ActivateField name='ignoreScreensConditions' />
                </Grid>
              )}
              <Grid item xs={isBatchUpdate ? 11 : 12}>
                <Field
                  name='content.ignoreScreensConditions'
                  component={ReduxSwitch}
                  label={t('screens.createScreenForm.ignoreScreensConditions')}
                  disabled={isBatchUpdate && !selectedProperties?.ignoreScreensConditions}
                />
                <Typography display='block' variant='caption'>
                  {t('screens.createScreenForm.ignoreScreensConditionsHelper')}
                </Typography>
              </Grid>
            </Fragment>
          )}
        </Fragment>
      )}
      {type === ScreenType.price && (
        <Fragment>
          {isBatchUpdate && (
            <Grid item xs={1}>
              <ActivateField name={type === 'price' ? 'listId' : 'playlistId'} />
            </Grid>
          )}
          <Grid item xs={isBatchUpdate ? 11 : 12}>
            <Field
              name='listId'
              placeholder={t('screens.selectList')}
              label={t('screens.content.list')}
              disabled={isBatchUpdate && !selectedProperties?.listId}
              options={[
                {
                  value: 'none',
                  label: t('screens.noList')
                },
                ...selectListsAsOptions(lists)
              ]}
              component={ReduxSelectWithAutocomplete}
              OptionIcon={TableColumnIcon}
            />
          </Grid>
        </Fragment>
      )}
    </Fragment>
  )
}

export default ContentSelectionField
