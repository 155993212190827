import { handleActions } from 'redux-actions'
import {
  createChannelItemSuccess,
  createChannelSuccess,
  deleteChannelItemSuccess,
  deleteChannelSuccess,
  getChannel,
  getChannelFail,
  getChannelSuccess,
  listChannels,
  listChannelsFail,
  listChannelsSuccess,
  openChannelsDialog,
  updateChannelItemSuccess,
  updateChannelSuccess,
  resetChannels,
  openEditChannelItem,
  closeEditChannelItem,
  listChannelItems,
  listChannelItemsFail,
  listChannelItemsSuccess,
  deleteChannelItemFail,
  deleteChannelItem
} from '../actions/channels'
import {
  Action,
  CreateChannelItemSuccessAction,
  CreateChannelSuccess,
  DeleteChannelItemSuccessAction,
  DeleteChannelSuccessAction,
  GetChannelSuccess,
  ListChannelItemsSuccess,
  ListChannelsSuccess,
  OpenEditChannelItemAction,
  UpdateChannelItemSuccessAction
} from '../types/actions'
import { ChannelsState } from '../types/states'
import { AllChannels } from '../types/channels'

const initialState: ChannelsState = {
  channels: {},
  parentChannels: {},
  channelItems: {},
  isGetChannelLoading: false,
  isListChannelsLoading: false,
  isListChannelItemsLoading: false,
  editChannelItemOpen: false
}

const channelsReducer = handleActions<ChannelsState, any>(
  {
    [resetChannels]: () => initialState,
    [createChannelSuccess]: (state, { payload: channel }: CreateChannelSuccess) => ({
      ...state,
      channels: {
        ...state.channels,
        [channel.channelId]: channel
      }
    }),
    [createChannelItemSuccess]: (
      state,
      { payload: { channelId, item } }: CreateChannelItemSuccessAction
    ) => ({
      ...state,
      channelItems: {
        ...state.channelItems,
        [channelId]: {
          ...state.channelItems[channelId],
          [item.itemId]: item
        }
      }
    }),
    [updateChannelItemSuccess]: (
      state,
      { payload: { channelId, item } }: UpdateChannelItemSuccessAction
    ) => ({
      ...state,
      channelItems: {
        ...state.channelItems,
        [channelId]: {
          ...state.channelItems[channelId],
          [item.itemId]: item
        }
      }
    }),
    [deleteChannelItem]: state => ({
      ...state,
      isChannelItemsLoading: true
    }),
    [deleteChannelItemFail]: state => ({
      ...state,
      isChannelItemsLoading: false
    }),
    [deleteChannelItemSuccess]: (
      state,
      { payload: { channelId, itemId } }: DeleteChannelItemSuccessAction
    ) => {
      const channelItems = {
        ...state.channelItems,
        [channelId]: {
          ...state.channelItems[channelId]
        }
      }
      delete channelItems[channelId][itemId]
      return {
        ...state,
        channelItems,
        isChannelItemsLoading: false
      }
    },
    [getChannel]: state => ({
      ...state,
      isGetChannelLoading: true
    }),
    [getChannelSuccess]: (state, { payload: channel }: GetChannelSuccess) => ({
      ...state,
      channels: {
        ...state.channels,
        [channel.channelId]: channel
      },
      isGetChannelLoading: false
    }),
    [getChannelFail]: state => ({
      ...state,
      isGetChannelLoading: false
    }),
    [listChannels]: state => ({
      ...state,
      isListChannelsLoading: true
    }),
    [listChannelsFail]: state => ({
      ...state,
      isListChannelsLoading: false
    }),
    [listChannelsSuccess]: (
      state,
      { payload: { environmentId, channelsArray } }: ListChannelsSuccess
    ) => {
      const { channels, parentChannels } = channelsArray.reduce(
        (channelsAcc: AllChannels, channel) => {
          if (environmentId === channel.environmentId) {
            channelsAcc.channels[channel.channelId] = {
              ...channel
            }
          } else {
            channelsAcc.parentChannels[channel.channelId] = {
              ...channel
            }
          }

          return channelsAcc
        },
        { channels: {}, parentChannels: {} }
      )
      return {
        ...state,
        channels,
        parentChannels,
        isListChannelsLoading: false
      }
    },
    [listChannelItems]: state => ({
      ...state,
      isChannelItemsLoading: true
    }),
    [listChannelItemsFail]: state => ({
      ...state,
      isChannelItemsLoading: false
    }),
    [listChannelItemsSuccess]: (
      state,
      { payload: { channelId, channelItemsArray } }: ListChannelItemsSuccess
    ) => {
      const channelItems = {
        ...state.channelItems,
        [channelId]: channelItemsArray.reduce((itemsAcc, item) => {
          itemsAcc[item.itemId] = item
          return itemsAcc
        }, {})
      }
      return {
        ...state,
        channelItems,
        isChannelItemsLoading: false
      }
    },
    [deleteChannelSuccess]: (state, { payload: channelId }: DeleteChannelSuccessAction) => {
      const channels = { ...state.channels }
      delete channels[channelId]
      return {
        ...state,
        channels
      }
    },
    [openChannelsDialog]: (state, { payload: { event } }: Action<any>) => ({
      ...state,
      selectedCalendarEvent: event
    }),
    [updateChannelSuccess]: (state, { payload: updatedChannel }: Action<any>) => ({
      ...state,
      channels: {
        ...state.channels,
        [updatedChannel.channelId]: updatedChannel
      }
    }),
    [openEditChannelItem]: (state, { payload: { event } }: OpenEditChannelItemAction) => ({
      ...state,
      selectedCalendarEvent: event,
      editChannelItemOpen: true
    }),
    [closeEditChannelItem]: state => ({
      ...state,
      editChannelItemOpen: false
    })
  },
  initialState
)

export default channelsReducer
