import React from 'react'
import { Field } from 'redux-form'
import { Grid, MenuItem, Typography, Paper } from '@mui/material'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { RecurringFrequency } from '@seesignage/seesignage-utils'
import {
  ReduxTextField,
  ReduxDateTimePicker,
  ReduxCheckbox
} from '../../../components/FormInput/ReduxWrappers'
import { required } from '../../../validation'
import { RecurringRuleFormValue } from '../../../types/channels'
import { parseNumberField } from '../../../utils/forms'

const getFrequencyOptions = (t: TFunction, selectedInterval?: number) => [
  {
    value: RecurringFrequency.WEEKLY,
    label: t('channels.recurrency.weekly', { count: selectedInterval || 1 })
  },
  {
    value: RecurringFrequency.MONTHLY,
    label: t('channels.recurrency.monthly', { count: selectedInterval || 1 })
  }
]

interface OwnProps {
  disabled?: boolean
  /** Recurring rule object from form */
  ruleValue?: RecurringRuleFormValue
}

const RecurringScheduleField: React.FC<OwnProps> = ({ ruleValue, disabled }) => {
  const [t] = useTranslation()
  const intervalValue = ruleValue?.interval
  const noEndDate = ruleValue?.noEndDate
  return (
    <Grid item xs={12}>
      <Paper style={{ padding: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={3} style={{ paddingTop: 30 }}>
            <Typography variant='body1'>{t('channels.recurrency.repeatEvery')}</Typography>
          </Grid>
          <Grid item xs={3} sm={2} style={{ paddingTop: 20 }}>
            <Field
              style={{ margin: 4 }}
              fullWidth
              disabled={disabled}
              name='channelItem.recurringRule.interval'
              type='number'
              required
              validate={[required]}
              parse={parseNumberField}
              InputProps={{
                inputProps: { min: 0, max: 52, step: 1 }
              }}
              component={ReduxTextField}
            />
          </Grid>
          <Grid item xs={4} sm={3} style={{ paddingTop: 20 }}>
            <Field
              style={{ margin: 4 }}
              fullWidth
              disabled={disabled}
              name='channelItem.recurringRule.freq'
              component={ReduxTextField}
              required
              validate={[required]}
              select>
              {getFrequencyOptions(t, intervalValue).map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Grid container>
              <Grid item xs={7} sm={5} style={{ paddingTop: 10 }}>
                <Field
                  disabled={disabled}
                  name='channelItem.recurringRule.noEndDate'
                  component={ReduxCheckbox}
                  color='primary'
                  label={t('channels.recurrency.noEnd')}
                />
              </Grid>
              <Grid item xs={5} sm={4}>
                <Field
                  style={{ marginBottom: 8 }}
                  fullWidth
                  disabled={noEndDate || disabled}
                  name='channelItem.recurringRule.endDate'
                  component={ReduxDateTimePicker}
                  label={t('channels.recurrency.end')}
                  helperText={t('general.optional')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
export default RecurringScheduleField
