import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import DeleteIcon from 'mdi-react/DeleteIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import LockIcon from 'mdi-react/LockIcon'
import { Channel } from '@seesignage/seesignage-utils'
import SyncIcon from 'mdi-react/RayStartVertexEndIcon'
import PoundBoxIcon from 'mdi-react/PoundBoxIcon'
import dateFormat from 'dateformat'
import { CloseDialog, DeleteChannel, Navigate, UpdateChannel } from '../../types/actions'
import Dialog from '../Dialog'
import TruncatedChip from '../../components/Chips'
import colors from '../../styles/common/colors'
import { validateDocumentWriteAccess } from '../../utils/permissions'
import { UserInterface } from '../../types/users'
import { convertTagsForFormField } from '../../utils/conversion'
import CreateChannelForm from './Forms/CreateChannelForm'

const useStyles = makeStyles(() => ({
  card: {
    width: 300,
    height: 150,
    display: 'inline-block' as any,
    verticalAlign: 'middle' as any,
    margin: '0px 5px 15px',
    position: 'relative' as any
  },
  cardHeader: {
    width: 200
  },
  title: {
    width: 260
  },
  selectArea: {
    cursor: 'pointer' as any,
    userSelect: 'none' as any,
    '-moz-user-select': 'none' as any,
    '-ms-user-select': 'none' as any,
    '-webkit-user-select': 'none' as any,
    height: 240 as any
  },
  actions: {
    float: 'right' as any,
    position: 'absolute' as any,
    width: '100%',
    bottom: 0,
    right: 0
  }
}))

interface OwnProps {
  channel: Channel
  navigate: Navigate
  closeDialog: CloseDialog
  update: UpdateChannel
  deleteC: DeleteChannel
  user: UserInterface
}

type ChannelCardProps = OwnProps
const ChannelCard: React.FC<ChannelCardProps> = ({
  channel,
  user,
  navigate,
  deleteC,
  update,
  closeDialog
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const {
    channelId,
    environmentId,
    name,
    tags,
    permissionTags,
    syncPlay,
    permissions,
    defaultPlaylistInterval,
    updatedAt,
    createdAt
  } = channel
  const isWriteDisabled = !validateDocumentWriteAccess(channel, user)
  return (
    <Card className={classes.card}>
      <CardActionArea
        onClick={() => navigate(`/environments/${environmentId}/channels/${channelId}`)}>
        <Tooltip disableInteractive placement='bottom' title={name}>
          <div>
            <CardHeader
              className={classes.cardHeader}
              title={
                <Typography noWrap variant='h6' className={classes.title}>
                  {name}
                </Typography>
              }
              subheader={
                <Typography variant='caption' display='block'>
                  {t('channels.card.updatedAt', {
                    date: updatedAt
                      ? dateFormat(updatedAt, 'dd.mm.yyyy HH:MM')
                      : dateFormat(createdAt, 'dd.mm.yyyy HH:MM')
                  })}
                </Typography>
              }
            />
          </div>
        </Tooltip>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <div style={{ overflow: 'auto', height: 44, width: '100%', paddingLeft: '10px' }}>
          {permissionTags &&
            permissionTags.map(tag => (
              <TruncatedChip
                key={tag}
                tag={tag}
                Icon={LockIcon}
                color='default'
                truncateLimit={20}
              />
            ))}
          {tags &&
            tags.map(tag => (
              <TruncatedChip
                key={tag}
                tag={tag}
                Icon={PoundBoxIcon}
                color='default'
                truncateLimit={20}
              />
            ))}
        </div>
        {syncPlay && (
          <Tooltip disableInteractive title={t('channels.card.syncPlay')}>
            <span>
              <SyncIcon color={colors.seesignageColor} />
            </span>
          </Tooltip>
        )}
        <Dialog
          dialogId={`UpdateChannelDialog.${channelId}`}
          title={`${t('channels.actions.edit')} (${name})`}
          ButtonIcon={SettingsIcon}
          tooltipTitle={
            isWriteDisabled ? t('channels.actions.editNoPermission') : t('channels.actions.edit')
          }
          buttonDisabled={isWriteDisabled}
          Content={
            <CreateChannelForm
              dialogId={`UpdateChannelDialog.${channelId}`}
              submitButtonLabel={t('general.update')}
              onSubmit={update}
              closeDialog={closeDialog}
              isUpdate
              initialValues={{
                name,
                channelId,
                syncPlay,
                tags: tags ? tags.map(tag => ({ value: tag, label: tag })) : undefined,
                permissionTags: convertTagsForFormField(permissionTags),
                permissions,
                defaultPlaylistInterval
              }}
            />
          }
        />
        <Dialog
          dialogId={`removeChannelDialog.${channelId}`}
          title={`${t('channels.actions.removeChannel')} (${name})`}
          ButtonIcon={DeleteIcon}
          tooltipTitle={
            isWriteDisabled
              ? t('channels.actions.removeChannelNoPermission')
              : t('channels.actions.removeChannel')
          }
          buttonDisabled={isWriteDisabled}
          Content={
            <Fragment>
              <DialogContent>
                <DialogContentText>{t('channels.actions.deletionConfirmation')}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => closeDialog(`removeChannelDialog.${channelId}`)}
                  color='primary'>
                  {t('general.cancel')}
                </Button>
                <Button onClick={() => deleteC(channelId)} color='primary' autoFocus>
                  {t('general.delete')}
                </Button>
              </DialogActions>
            </Fragment>
          }
        />
      </CardActions>
    </Card>
  )
}

export default ChannelCard
