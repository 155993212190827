import {
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  MenuItem,
  Badge,
  Button,
  Paper
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CheckBoxMultipleOutlineIcon from 'mdi-react/CheckBoxMultipleOutlineIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import React, { useEffect, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { WrappedFieldInputProps } from 'redux-form'
import InfoIcon from 'mdi-react/InfoCircleIcon'
import { Media, MediaType } from '@seesignage/seesignage-utils'
import classNames from 'classnames'
import CloudUploadIcon from 'mdi-react/CloudUploadIcon'
import { useDropzone } from 'react-dropzone'
import {
  clearSearchMedia,
  deselectFolder,
  listFiles,
  setDropzoneFiles,
  searchMedia,
  clearDropzone,
  addMedia
} from '../../../actions/media'
import MediaGridList from '../../Media/MediaGridList'
import {
  selectMediaFoldersArray,
  selectSelectedFolderId,
  filterMediaByTerms,
  selectListFilesIsLoading,
  selectAllMediaFoldersArray,
  selectMediaFolderById,
  selectMediaByTypeAsArray,
  selectDropzoneFieldProps
} from '../../../selectors/media'
import { selectIsUserDeviceMobile } from '../../../selectors/users'
import { compareBySortType } from '../../../utils/sorting'
import Sorter from '../../Sorting'
import { SortByValue, SortDirection } from '../../../types/sortings'
import SearchField from '../../SearchField'
import { getFilesWithPreview } from '../../../utils/files'
import DragDropSnackbar from '../../Media/DragDropSnackbar'
import { getAcceptedMediaTypes } from '../../../utils/dropzone'
import { Tour } from '../../../types/tours'
import { forceRunTour } from '../../../actions/tours'
import useDebounce from '../../../hooks/debounce'
import { DEBOUNCE_DELAY } from '../../../config/constants'
import AddMediaForm from '../../../containers/MediaBrowser/Forms/AddMediaForm'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { SetDropzoneFilesActionParams, isMedia } from '../../../types/media'
import FolderCard from './FolderCard'
import CreateFolderFormPopover from './CreateFolderFormPopover'
import SingleMediaPreview from './SingleMediaPreview'
import RenameMediaFormPopover from './RenameMediaFormPopover'
import FoldersBreadcrumbs from './FoldersBreadcrumbs'

const useStyles = makeStyles(theme => ({
  selectToolbar: {
    position: 'sticky' as any,
    top: 0,
    zIndex: 1000,
    backgroundColor: 'white' as any
  },
  text: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    paddingLeft: theme.spacing(),
    fontWeight: 500,
    float: 'left'
  },
  toolbarContainer: {
    display: 'flex',
    width: '100%',
    height: 40,
    alignItems: 'center',
    margin: 8
  },
  folders: {
    paddingLeft: 4
  },
  dropzoneArea: {
    height: '450px',
    overflow: 'auto',
    width: '100%'
  },
  dragActive: {
    background: 'rgba(0, 174, 239, .1)'
  },
  dragActiveRoot: {
    boxShadow: 'inset 0px 0px 0px 5px rgba(0, 174, 239, .7)',
    transition: '200ms'
  },
  uploadMediaButton: {
    margin: 4
  },
  addMediaFormContainer: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  selectedText: {
    padding: '0 5px 0 5px'
  }
}))

interface InputProps extends WrappedFieldInputProps {
  value: string[] | string | Media | undefined
}

interface SelectMultipleMediaFieldProps {
  input: InputProps
  /**
   * When you want to use this component for adding media files to list
   * such as ad revolver playlist item or content editor carousel widget
   * */
  pushMediaToList?: (item: Media) => void
  /** Select only single file and set it as input value. (otherwise list) */
  isSingleFileInput?: boolean
  /** Use media as field value in single file input. Otherwise, use key as field value. */
  useMediaAsFieldValue?: boolean
  /** Use mediaType type if you want to accept only some specific type of media files.  */
  mediaType?: MediaType
  disabled?: boolean
  label?: string
  /** product tour */
  tour?: Tour
  /** name of the form where this field is used. */
  formName: string
}

/**
 * Select multiple media files and support uploading media to media browser.
 * This component is used when adding existing media items to playlist.
 */
const SelectMultipleMediaField: React.FC<SelectMultipleMediaFieldProps> = ({
  input,
  pushMediaToList,
  isSingleFileInput,
  useMediaAsFieldValue,
  mediaType,
  disabled,
  label,
  tour,
  formName
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const [sortBy, setSortBy] = useState<SortByValue>(SortByValue.createdAt)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.desc) // show the lastest folders/files first
  const [draggingOverFolderId, setDraggingOverFolderId] = useState<string | undefined>(undefined)
  const [searchTerm, setSearchTerm] = useState('')
  const searchTermDebounced = useDebounce(searchTerm, DEBOUNCE_DELAY)

  const { files: dropzoneFiles, parentFolderId: dropzoneParentFolderId } = useSelector(
    selectDropzoneFieldProps(formName, input.name)
  )

  const allMediaFiles = useSelector(selectMediaByTypeAsArray(mediaType))

  const mediaLoading = useSelector(selectListFilesIsLoading)
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const selectedFolderId = useSelector(selectSelectedFolderId)
  const selectedFolder = useSelector(selectMediaFolderById(selectedFolderId))

  const allFolders = useSelector(selectAllMediaFoldersArray)
  const foldersOfFolder = useSelector(selectMediaFoldersArray(selectedFolderId))

  const folders = searchTerm
    ? allFolders.filter(({ name }) =>
        name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    : foldersOfFolder

  const mediaFiles = filterMediaByTerms({
    selectedFolderId,
    searchTerm,
    keys: allMediaFiles
  })

  const draggingOverFolder = useSelector(selectMediaFolderById(draggingOverFolderId))
  const dropzoneFolder = useSelector(selectMediaFolderById(dropzoneParentFolderId))

  const mediaTargetFolder = draggingOverFolder || dropzoneFolder || selectedFolder

  const sortedMediaFiles = mediaFiles.sort(compareBySortType(sortBy, sortDirection))
  const sortedFolders = folders.sort(compareBySortType(sortBy, sortDirection))
  // when pushing media to list is used do not show select items buttons
  const allowSelectItems = !(typeof pushMediaToList === 'function')

  const fieldValue = input.value
  const sorterProps = {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    t
  }
  const singleSelectedMediaKey = isMedia(fieldValue)
    ? fieldValue.key
    : typeof fieldValue === 'string'
    ? fieldValue
    : undefined

  useEffect(() => {
    if (searchTermDebounced.length > 0) {
      dispatch(searchMedia(searchTermDebounced))
    }
  }, [searchTermDebounced, dispatch])

  const handleMountActions = () => {
    dispatch(listFiles())
    dispatch(deselectFolder())
    dispatch(clearSearchMedia())
    return () => {
      // unmount component
      dispatch(clearSearchMedia())
      dispatch(deselectFolder())
      setDraggingOverFolderId(undefined)
    }
  }
  useEffect(handleMountActions, [])

  // Drop media to media browser Dropzone
  const { getRootProps, isDragActive } = useDropzone({
    onDragLeave: () => {
      setDraggingOverFolderId(undefined)
    },
    onDrop: files => {
      const setDropzoneFilesActionParams: SetDropzoneFilesActionParams = {
        files: getFilesWithPreview(files),
        parentFolderId: mediaTargetFolder?.folderId,
        formName,
        formFieldName: input.name
      }
      dispatch(setDropzoneFiles(setDropzoneFilesActionParams))
      setDraggingOverFolderId(undefined)
    },
    noClick: true,
    noKeyboard: true
    // NOTE: do not use accept here because we want to allow all files because redux-form
    // handles the file type validation.
  })

  // Add media button Dropzone
  const { getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    onDrop: acceptedFiles => {
      const setDropzoneFilesActionParams: SetDropzoneFilesActionParams = {
        files: getFilesWithPreview(acceptedFiles),
        parentFolderId: mediaTargetFolder?.folderId,
        formName,
        formFieldName: input.name
      }
      dispatch(setDropzoneFiles(setDropzoneFilesActionParams))
    },
    noClick: true,
    noKeyboard: true,
    multiple: isSingleFileInput ? false : true,
    accept: getAcceptedMediaTypes(mediaType)
  })

  const selectMedia = (media: Media) => {
    if (isSingleFileInput) {
      input.onChange(useMediaAsFieldValue ? media : media.key)
    } else {
      input.onChange([...(Array.isArray(fieldValue) ? fieldValue : []), media.key])
    }
  }

  const deselectMedia = (key: string) => {
    if (isSingleFileInput) {
      input.onChange(null)
    } else if (Array.isArray(fieldValue)) {
      input.onChange(fieldValue.filter(selectedKey => key !== selectedKey))
    }
  }

  const selectAll = () => {
    const keysArray = mediaFiles.map(({ key }) => key)
    input.onChange([...new Set([...(Array.isArray(fieldValue) ? fieldValue : []), ...keysArray])])
  }

  const deselectAll = () => {
    input.onChange([])
  }

  if (disabled) {
    return <Fragment></Fragment>
  }

  return (
    <div>
      {label && <Typography gutterBottom>{label}</Typography>}
      {mediaLoading && <LinearProgress />}
      {isSingleFileInput && singleSelectedMediaKey ? (
        <SingleMediaPreview
          isMobile={isMobile}
          mediaKey={singleSelectedMediaKey}
          deselectMedia={deselectMedia}
        />
      ) : (
        <Fragment>
          {dropzoneFiles && dropzoneFiles.length > 0 && (
            <Paper className={classes.addMediaFormContainer}>
              <Typography variant='h6' gutterBottom>
                {t('media.uploadNewMediaFiles')}
              </Typography>
              <AddMediaForm
                // Dynamically generate form name for AddMediaForm because infopage
                // template can have multiple add media forms visible at the same time
                form={`AddMediaForm-${input.name.replaceAll(/[.\\[\]]/gm, '-')}`}
                submitButtonLabel={
                  mediaType
                    ? mediaType === MediaType.image
                      ? t('media.uploadImage')
                      : t('media.uploadVideo')
                    : t('media.upload')
                }
                backButtonLabel={t('general.cancel')}
                backButtonOnClick={() => dispatch(clearDropzone())}
                onSubmit={bindSubmitActionToPromise(dispatch, addMedia)}
                initialValues={{
                  parentFolderId: mediaTargetFolder
                    ? { value: mediaTargetFolder.folderId, label: mediaTargetFolder.name }
                    : { value: null, label: 'Root' },
                  quality: 'fullhd',
                  files: dropzoneFiles && dropzoneFiles.length > 0 ? dropzoneFiles : undefined,
                  isSingleFileInput,
                  useMediaAsFieldValue,
                  // in common use case we want to autoselect the media in some other form after media upload is done.
                  formNameToAddMedia: formName,
                  formFieldNameToAddMedia: input.name,
                  allowOnlyMediaType: mediaType
                }}
                showDropzone={false}
                useFormOnSubmit={false}
              />
            </Paper>
          )}
          <Grid container>
            <Grid item xs={12} className={classes.selectToolbar}>
              <Grid container justifyContent='flex-end'>
                <Grid item>
                  <Button
                    id='upload-media-button'
                    className={classes.uploadMediaButton}
                    variant='contained'
                    color='primary'
                    startIcon={<CloudUploadIcon />}
                    onClick={open}
                    disabled={dropzoneFiles && dropzoneFiles?.length > 0}>
                    {t('media.uploadNew')}
                  </Button>
                  <input {...getInputProps()} />
                </Grid>
              </Grid>
              <Grid container justifyContent='flex-end' alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <SearchField
                    placeholder={t('general.search')}
                    onChange={e => {
                      if (typeof setSearchTerm === 'function') {
                        setSearchTerm(e.target.value)
                      }
                    }}
                    value={searchTerm}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid container justifyContent='flex-end' alignItems='center'>
                    <CreateFolderFormPopover parentFolderId={selectedFolderId} />
                    {allowSelectItems && (
                      <Fragment>
                        {Array.isArray(fieldValue) && fieldValue.length === 1 && (
                          <RenameMediaFormPopover mediaKey={fieldValue[0]} />
                        )}
                        {!isSingleFileInput && (
                          <Tooltip disableInteractive title={t('general.selectAll')}>
                            <IconButton
                              id='select-all-media-button'
                              onClick={() => selectAll()}
                              size='large'>
                              <CheckBoxMultipleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {Array.isArray(fieldValue) && fieldValue.length > 0 && (
                          <Fragment>
                            <Tooltip disableInteractive title={t('general.deselect')}>
                              <IconButton onClick={() => deselectAll()} size='large'>
                                <Badge badgeContent={fieldValue.length} color='primary'>
                                  <CloseIcon />
                                </Badge>
                              </IconButton>
                            </Tooltip>
                            <Typography>{t('general.selected')}</Typography>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                    <Sorter {...sorterProps}>
                      <MenuItem value='name'>{t('sorting.sortBy.name')}</MenuItem>
                      <MenuItem value='createdAt'>{t('sorting.sortBy.createdAt')}</MenuItem>
                      <MenuItem value='size'>{t('sorting.sortBy.size')}</MenuItem>
                    </Sorter>
                    {tour && (
                      <Tooltip disableInteractive title={t('tours.runTour')}>
                        <IconButton
                          onClick={() => {
                            dispatch(forceRunTour(tour))
                          }}
                          size='large'>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <FoldersBreadcrumbs selectedFolderId={selectedFolderId} />
            </Grid>
            <div
              {...getRootProps({
                className: 'dropzone'
              })}
              className={classNames(classes.dropzoneArea, {
                [classes.dragActive]: isDragActive,
                [classes.dragActiveRoot]: isDragActive && !draggingOverFolderId
              })}
              id='dropzone-area'>
              {folders.length > 0 && (
                <Typography className={classes.text} variant='caption'>
                  {t('media.folders.folders')}
                </Typography>
              )}
              <Grid
                className={classes.folders}
                container
                alignItems={isMobile ? 'center' : 'flex-start'}
                justifyContent={isMobile ? 'center' : 'flex-start'}>
                {sortedFolders.map(folder => (
                  <FolderCard
                    key={folder.folderId}
                    folder={folder}
                    setDraggingOverFolderId={setDraggingOverFolderId}
                  />
                ))}
              </Grid>
              <Typography className={classes.text} variant='caption'>
                {t('media.files')}
              </Typography>
              <Grid item xs={12}>
                <MediaGridList
                  mediaFiles={sortedMediaFiles}
                  selectMedia={selectMedia}
                  deselectMedia={deselectMedia}
                  selectedMediaKeys={Array.isArray(fieldValue) ? fieldValue : []}
                  pushMediaToList={pushMediaToList}
                />
              </Grid>
            </div>
          </Grid>
        </Fragment>
      )}
      <DragDropSnackbar
        open={isDragActive}
        label={t('media.uploadToFolder')}
        mediaTargetFolderName={mediaTargetFolder?.name || 'Root'}
      />
    </div>
  )
}

export default SelectMultipleMediaField
