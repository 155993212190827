import {
  Playlist,
  PlaylistItem,
  PlaylistDuration,
  CreatePlaylist,
  PlaylistUI,
  PlaylistResponseType,
  PlaylistResponseForContentMediaCarousel
} from '@seesignage/seesignage-utils'
import { API } from 'aws-amplify'

const getPlaylists = async (environmentId: string, includeParentPlaylists?: boolean) => {
  const options = includeParentPlaylists
    ? {
        queryStringParameters: {
          includeParentPlaylists: true
        }
      }
    : {}
  const playlists: Playlist[] = await API.get(
    'playlists',
    `/environments/${environmentId}/playlists`,
    options
  )
  return playlists
}

const getPlaylist = async <T extends PlaylistUI | PlaylistResponseForContentMediaCarousel>(
  environmentId: string,
  playlistId: string,
  responseType?: PlaylistResponseType
) => {
  const playlist: T = await API.get(
    'playlists',
    `/environments/${environmentId}/playlists/${playlistId}`,
    {
      queryStringParameters: {
        responseType
      }
    }
  )
  return playlist
}

const deletePlaylist = async (environmentId: string, playlistId: string) => {
  await API.del('playlists', `/environments/${environmentId}/playlists/${playlistId}`, {})
}

const createPlaylist = async (environmentId: string, playlist: any) => {
  const newScreen = await API.post('playlists', `/environments/${environmentId}/playlists`, {
    body: playlist
  })
  return newScreen
}

const updatePlaylist = async (
  environmentId: string,
  playlistId: string,
  playlist: CreatePlaylist
) => {
  const updatedPlaylist: PlaylistUI = await API.put(
    'playlists',
    `/environments/${environmentId}/playlists/${playlistId}`,
    { body: playlist }
  )
  return updatedPlaylist
}

const addMultiplePlaylistItems = async (
  environmentId: string,
  playlistId: string,
  items: any[]
) => {
  const addedItems = await API.post(
    'playlists',
    `/environments/${environmentId}/playlists/${playlistId}/multipleItems`,
    { body: items }
  )
  return addedItems
}

const copyPlaylist = async (environmentId: string, playlistId: string) => {
  const playlist = await API.get(
    'playlists',
    `/environments/${environmentId}/playlists/${playlistId}/copy`,
    {}
  )
  return playlist
}

const updatePlaylistItems = async (
  environmentId: string,
  playlistId: string,
  items: PlaylistItem[],
  totalDuration?: PlaylistDuration
) => {
  const playlist: PlaylistUI = await API.put(
    'playlists',
    `/environments/${environmentId}/playlists/${playlistId}/items/update`,
    {
      body: {
        items,
        totalDuration
      }
    }
  )
  return playlist
}

export default {
  getPlaylists,
  getPlaylist,
  deletePlaylist,
  createPlaylist,
  updatePlaylist,
  addMultiplePlaylistItems,
  copyPlaylist,
  updatePlaylistItems
}
