import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChannelItemPlaylist } from '@seesignage/seesignage-utils'
import EditPlaylist from '../../../Playlists/EditPlaylist'
import { getUpdateChannelItemFormInitialValues } from '../../../../utils/channels'
import CreateChannelItemForm from '../../Forms/CreateChannelItemForm'
import { validatePlaylistWriteAccess } from '../../../../utils/permissions'
import { selectUser } from '../../../../selectors/users'
import { selectSelectedPlaylist } from '../../../../selectors/playlists'
import { CalendarEvent } from '../../../../types/channels'

const useStyles = makeStyles(() => ({
  playlistSettings: {
    maxWidth: 700
  }
}))

const EditChannelItemPlaylist = ({
  selectedChannelItem,
  selectedCalendarEvent
}: {
  selectedChannelItem: ChannelItemPlaylist
  selectedCalendarEvent: CalendarEvent
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const selectedPlaylist = useSelector(selectSelectedPlaylist)
  const user = useSelector(selectUser)

  const userHasAccess = selectedCalendarEvent?.userHasAccess
  const userHasWriteAccess =
    selectedPlaylist && user ? validatePlaylistWriteAccess(selectedPlaylist, user) : false

  return (
    <Fragment>
      <div className={classes.playlistSettings}>
        {selectedChannelItem.recurringRule && (
          <Typography style={{ padding: 8, margin: 8 }} color='primary' variant='body1'>
            {t('channels.recurrency.recurringEvent')}
          </Typography>
        )}
        {!userHasAccess && (
          <Typography style={{ padding: 8, margin: 8 }} color='secondary' variant='body1'>
            {t('channels.forms.noAccess')}
          </Typography>
        )}
        <div style={{ padding: 8, margin: 8 }}>
          <CreateChannelItemForm
            form='UpdateChannelItemForm'
            isUpdate
            userHasAccess={userHasAccess && userHasWriteAccess}
            submitButtonLabel={t('general.update')}
            event={selectedCalendarEvent}
            initialValues={{
              ...getUpdateChannelItemFormInitialValues(
                selectedPlaylist,
                selectedChannelItem,
                selectedCalendarEvent
              ),
              itemId: selectedCalendarEvent?.itemId
            }}
          />
        </div>
      </div>
      <EditPlaylist />
    </Fragment>
  )
}

export default EditChannelItemPlaylist
