import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import {
  parseRecurringRule,
  RecurringRule,
  RecurringFrequency,
  ChannelItemPriority,
  PlaylistUI,
  ChannelItem,
  LogicalOperator,
  getDateFromScheduleDateTime,
  getDateFromScheduleTime,
  AutocompleteOption,
  ChannelItemScreenCondition,
  isChannelItemPlaylist,
  isChannelItemCampaign,
  ChannelItemPlaylist
} from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { toast } from 'react-toastify'
import { View } from 'react-big-calendar'
import { CalendarEvent, CalendarSlot, UpdateChannelItemFormData } from '../types/channels'
import { PlaylistsById } from '../types/playlists'
import { OpenDialog } from '../types/actions'
import { CampaignsById, IndexById } from '../types/states'
import colors from '../styles/common/colors'
import { convertAutocompleteFormFieldValue, convertTagsForFormField } from './conversion'

export const MAX_OVERLAPPING_PLAYLISTS_LIMIT = 40

const moment = extendMoment(Moment)

const getPlaylistProperties = (playlist?: PlaylistUI) => {
  if (playlist) {
    const { defaultInterval, items, name, userHasAccess, permissionTags } = playlist
    return {
      defaultInterval,
      items,
      name,
      userHasAccess,
      permissionTags
    }
  }
  return {}
}

/**
 * Convert channel items to events for 'react-big-calendar'
 */
const convertChannelItemsToEvents = (
  channelItems: ChannelItem[],
  playlistsById: PlaylistsById,
  campaignsById: CampaignsById
): CalendarEvent[] =>
  channelItems.reduce((events: CalendarEvent[], item) => {
    if (isChannelItemPlaylist(item)) {
      const {
        environmentId,
        playlistId,
        recurringRule,
        conditions,
        itemId,
        priority,
        start,
        end,
        startTime,
        endTime
      } = item
      const playlist = playlistsById[playlistId]
      const { items, name, userHasAccess } = getPlaylistProperties(playlist)
      if (recurringRule) {
        const recurringEvents = parseRecurringRule(start, end, recurringRule)
        for (const event of recurringEvents) {
          const { startDate: recurringStart, endDate: recurringEnd } = event
          events.push({
            itemId,
            playlistId,
            environmentId,
            title: name || '',
            playlistItemsCount: items ? items.length : undefined,
            start: getDateFromScheduleDateTime(recurringStart),
            end: getDateFromScheduleDateTime(recurringEnd),
            startTime: startTime ? getDateFromScheduleTime(startTime) : undefined,
            endTime: endTime ? getDateFromScheduleTime(endTime) : undefined,
            recurringRule,
            priority,
            userHasAccess,
            tags: conditions?.tags
          })
        }
      } else {
        events.push({
          itemId,
          playlistId,
          environmentId,
          title: name || '',
          playlistItemsCount: items ? items.length : undefined,
          start: getDateFromScheduleDateTime(start),
          end: getDateFromScheduleDateTime(end),
          startTime: startTime ? getDateFromScheduleTime(startTime) : undefined,
          endTime: endTime ? getDateFromScheduleTime(endTime) : undefined,
          priority,
          userHasAccess,
          tags: conditions?.tags
        })
      }
    } else if (isChannelItemCampaign(item)) {
      const { environmentId, campaignId, itemId, priority, start, end } = item
      const campaign = campaignsById[campaignId]
      events.push({
        itemId,
        environmentId,
        title: campaign?.name,
        start: getDateFromScheduleDateTime(start),
        end: getDateFromScheduleDateTime(end),
        campaignId,
        priority,
        userHasAccess: true
      })
    }

    return events
  }, [])

interface CheckIfSlotOverlapsTooManyCalendarEventsParams {
  events: CalendarEvent[]
  start: string | Date
  end: string | Date
  skipItemId?: string
  recurringRule?: RecurringRule | null
}

/**
 * Verify selected slot doesn't overlap with over X number of calendar events.
 * If recurring rule is provided, check also that no recurring events overlap
 */
const checkIfSlotOverlapsTooManyCalendarEvents = ({
  events,
  start,
  end,
  skipItemId,
  recurringRule
}: CheckIfSlotOverlapsTooManyCalendarEventsParams) => {
  const matches = events.filter(({ start: startDate, end: endDate, itemId }) => {
    if (skipItemId === itemId) {
      return false
    }
    if (recurringRule) {
      const dateRanges = parseRecurringRule(
        new Date(start).toISOString(),
        new Date(end).toISOString(),
        recurringRule
      )

      const recurringMatch = dateRanges.find(
        ({ startDate: recurringStart, endDate: recurringEnd }) => {
          const existingRange = moment.range(new Date(startDate), new Date(endDate))
          const newRange = moment.range(
            getDateFromScheduleDateTime(recurringStart),
            getDateFromScheduleDateTime(recurringEnd)
          )
          return newRange.overlaps(existingRange)
        }
      )
      return recurringMatch
    } else {
      const existingRange = moment.range(new Date(startDate), new Date(endDate))
      const newRange = moment.range(new Date(start), new Date(end))
      return newRange.overlaps(existingRange)
    }
  })

  const indexedEventsMatches = matches.reduce((indexedEvents: IndexById<CalendarEvent>, event) => {
    indexedEvents[event.itemId] = {
      ...event
    }
    return indexedEvents
  }, {})

  return Object.keys(indexedEventsMatches).length >= MAX_OVERLAPPING_PLAYLISTS_LIMIT
}

/**
 * Get initial values for recurring rule
 * @param selectedCalendarEvent
 */
const getRecurringRuleFormInitialValue = (selectedCalendarEvent?: CalendarEvent) => {
  if (selectedCalendarEvent) {
    const { recurringRule } = selectedCalendarEvent
    return {
      interval: recurringRule?.interval || 1,
      freq: recurringRule?.freq || RecurringFrequency.WEEKLY,
      noEndDate: recurringRule?.endDate ? false : true,
      endDate: recurringRule?.endDate
    }
  }
  return undefined
}

/**
 * onSelectSlot event for react-big-calendar
 */
const onSelectSlot = (
  event: CalendarSlot,
  events: CalendarEvent[],
  view: View,
  openDialog: OpenDialog,
  t: TFunction
) => {
  if (view === 'month') {
    // react-big-calendar has bug where endDate is one day more than expected
    // https://github.com/jquense/react-big-calendar/issues/2563
    const fixedEndDate = moment(event.end).subtract(1, 'day')

    event.start = new Date(event.start)
    event.end = fixedEndDate.endOf('day').toDate()
  }
  if (checkIfSlotOverlapsTooManyCalendarEvents({ events, start: event.start, end: event.end })) {
    toast.warn(
      t('channels.calendar.collapsesPlaylist', {
        maxOverlappingPlaylistsLimit: MAX_OVERLAPPING_PLAYLISTS_LIMIT
      }),
      { autoClose: 15000 }
    )
  } else {
    openDialog({ event, id: 'createChannelItem' })
  }
}

const eventStyleByPriority = {
  [ChannelItemPriority.low]: { backgroundColor: colors.priorities.low },
  [ChannelItemPriority.medium]: { backgroundColor: colors.priorities.medium },
  [ChannelItemPriority.high]: { backgroundColor: colors.priorities.high },
  [ChannelItemPriority.emergency]: { backgroundColor: colors.priorities.emergency }
}

const eventChipStyleByPriority = {
  [ChannelItemPriority.low]: { color: colors.priorities.low, backgroundColor: 'white' },
  [ChannelItemPriority.medium]: { color: colors.priorities.medium, backgroundColor: 'white' },
  [ChannelItemPriority.high]: { color: colors.priorities.high, backgroundColor: 'white' },
  [ChannelItemPriority.emergency]: { color: colors.priorities.emergency, backgroundColor: 'white' }
}

const playlistPriorityStyleGetter = (priority: ChannelItemPriority) =>
  eventStyleByPriority[priority || ChannelItemPriority.low]

/**
 * eventPropGetter event for react-big-calendar
 * Specify custom event styles here
 */
const eventPropGetter = ({ priority }: CalendarEvent) => {
  return {
    // by default use 'low' priority style
    style: eventStyleByPriority[priority || ChannelItemPriority.low]
  }
}

const mappedPrioritiesValues = Object.values(ChannelItemPriority)
const prioritiesValues = mappedPrioritiesValues.slice(mappedPrioritiesValues.length / 2)

const getPrioritiesValues = () => prioritiesValues

const priorities = [
  {
    label: ChannelItemPriority.low,
    color: colors.priorities.low
  },
  {
    label: ChannelItemPriority.medium,
    color: colors.priorities.medium
  },
  {
    label: ChannelItemPriority.high,
    color: colors.priorities.high
  },
  {
    label: ChannelItemPriority.emergency,
    color: colors.priorities.emergency
  }
]

const getPriorityOptions = () => priorities

const getPlaylistInitialValues = (selectedPlaylist: PlaylistUI) => {
  const { name, defaultInterval, permissionTags } = selectedPlaylist
  return {
    name,
    defaultInterval,
    permissionTags: permissionTags
      ? permissionTags.map(tag => ({ value: tag, label: tag }))
      : undefined
  }
}

const convertChannelItemConditionsScreensForFormField = (screens?: ChannelItemScreenCondition[]) =>
  screens
    ? screens.map(s => ({
        value: s.code,
        label: s.code,
        data: s
      }))
    : undefined

const getUpdateChannelItemInitialValues = (
  selectedChannelItem: ChannelItem,
  selectedCalendarEvent: CalendarEvent
) => {
  const {
    priority,
    start,
    end,
    startTime,
    endTime,
    conditions,
    recurringRule
  } = selectedChannelItem as ChannelItemPlaylist
  const formData: UpdateChannelItemFormData = {
    start: getDateFromScheduleDateTime(start),
    end: getDateFromScheduleDateTime(end),
    startTime: startTime ? getDateFromScheduleTime(startTime) : undefined,
    endTime: endTime ? getDateFromScheduleTime(endTime) : undefined,
    priority,
    conditions: conditions
      ? {
          operator: conditions.operator,
          tags: convertTagsForFormField(conditions.tags),
          screens: convertChannelItemConditionsScreensForFormField(conditions.screens)
        }
      : {
          operator: LogicalOperator.or
        },
    recurringEvent: recurringRule ? true : false,
    recurringRule: getRecurringRuleFormInitialValue(selectedCalendarEvent),
    playlistId: convertAutocompleteFormFieldValue(
      selectedCalendarEvent?.playlistId
    ) as AutocompleteOption
  }
  return formData
}

/**
 * Get initial values for CreateChannelItemForm when updating ChannelItem
 */
const getUpdateChannelItemFormInitialValues = (
  selectedPlaylist?: PlaylistUI,
  selectedChannelItem?: ChannelItem,
  selectedCalendarEvent?: CalendarEvent
) => ({
  playlist: selectedPlaylist ? getPlaylistInitialValues(selectedPlaylist) : undefined,
  channelItem:
    selectedChannelItem && selectedCalendarEvent
      ? getUpdateChannelItemInitialValues(selectedChannelItem, selectedCalendarEvent)
      : undefined
})

export {
  convertChannelItemsToEvents,
  checkIfSlotOverlapsTooManyCalendarEvents,
  getRecurringRuleFormInitialValue,
  onSelectSlot,
  playlistPriorityStyleGetter,
  eventPropGetter,
  getPrioritiesValues,
  getPriorityOptions,
  eventChipStyleByPriority,
  getUpdateChannelItemFormInitialValues
}
