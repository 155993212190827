import React from 'react'
import { useSelector } from 'react-redux'
import { selectSelectedCampaign } from '../../../../selectors/campaigns'
import EditCampaignButtons from '../../../Campaigns/EditCampaign/EditCampaignButtons'
import EditCampaignForm from '../../../Campaigns/EditCampaign/EditCampaignForm'
import { getCampaignInitialValues } from '../../../../utils/campaigns'

const EditChannelItemCampaign = () => {
  const selectedCampaign = useSelector(selectSelectedCampaign)
  return (
    <div>
      {selectedCampaign && <EditCampaignButtons campaign={selectedCampaign} />}
      <EditCampaignForm
        campaign={selectedCampaign}
        initialValues={selectedCampaign ? getCampaignInitialValues(selectedCampaign) : undefined}
      />
    </div>
  )
}

export default EditChannelItemCampaign
