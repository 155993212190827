import React from 'react'
import { FieldArray, reduxForm, InjectedFormProps } from 'redux-form'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { asyncValidateEmail } from '../../../validation/asyncValidate'
import EnvironmentUsersFieldComponent from '../../../components/FormInput/EnvironmentUsersFieldComponent'
import { AddUsersToEnvironmentFormData } from '../../../types/environments'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { addEnvironmentUsers as addEnvironmentUsersAction } from '../../../actions/environments'
import { closeDialog as closeDialogAction } from '../../../actions/dialogs'

type FormProps = AddUsersToEnvironmentFormData

const AddEnvironmentUsersForm: React.FC<InjectedFormProps<FormProps>> = ({
  handleSubmit,
  submitting,
  error
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const addUsers = bindSubmitActionToPromise(dispatch, addEnvironmentUsersAction)
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))
  return (
    <form onSubmit={handleSubmit(addUsers)}>
      <FieldArray name='users' component={EnvironmentUsersFieldComponent} props={{ t }} />
      <Grid container>
        <Grid item xs={12} sm={12}>
          {submitting && <LinearProgress />}
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={() => closeDialog('AddEnvironmentUsersDialog')} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {t('general.save')}
        </Button>
      </DialogActions>
    </form>
  )
}
export default reduxForm<FormProps>({
  form: 'AddEnvironmentUserForm',
  asyncValidate: asyncValidateEmail,
  asyncBlurFields: ['users[].email']
})(AddEnvironmentUsersForm)
