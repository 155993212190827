import { path } from 'ramda'
import { toast } from 'react-toastify'
import { SubmissionError } from 'redux-form'
import { all, call, put, select, takeLatest, take } from 'redux-saga/effects'
import {
  Playlist,
  Channel,
  RecurringRule,
  getScheduleDateTimeFromDate,
  getScheduleTimeFromString,
  ChannelItemConditions,
  ChannelItemFormData,
  ChannelItem,
  PlaylistUI
} from '@seesignage/seesignage-utils'
import isEqual from 'lodash.isequal'
import {
  createChannel,
  createChannelFail,
  createChannelItem,
  createChannelItemFail,
  createChannelItemSuccess,
  createChannelSuccess,
  deleteChannel,
  deleteChannelFail,
  deleteChannelItem,
  deleteChannelItemFail,
  deleteChannelItemSuccess,
  deleteChannelSuccess,
  getChannel,
  getChannelFail,
  getChannelSuccess,
  listChannels,
  listChannelsFail,
  listChannelsSuccess,
  openChannelsDialog,
  updateChannel,
  updateChannelFail,
  updateChannelItem,
  updateChannelItemFail,
  updateChannelItemSuccess,
  updateChannelSuccess,
  closeEditChannelItem,
  openEditChannelItem,
  listChannelItems,
  listChannelItemsSuccess,
  listChannelItemsFail
} from '../actions/channels'
import { closeDialog, openDialog } from '../actions/dialogs'
import { selectChannelItemsByChannelIdAsArray } from '../selectors/channels'
import { selectContentIdFromPathname, selectEnvironmentIdFromPathname } from '../selectors/routing'
import Api from '../services/api/channels'
import ChannelItemsApi from '../services/api/channelItems'
import PlaylistsApi from '../services/api/playlists'
import i18n from '../translations/i18n'
import {
  handleCreateChannelItemsParams,
  handleCreateChannelParams,
  handleUpdateChannelItemsParams,
  handleUpdateChannelParams
} from '../types/formData'
import {
  checkIfSlotOverlapsTooManyCalendarEvents,
  MAX_OVERLAPPING_PLAYLISTS_LIMIT,
  convertChannelItemsToEvents
} from '../utils/channels'
import {
  getPlaylist,
  createPlaylistSuccess,
  deselectPlaylist,
  getPlaylistSuccess,
  updatePlaylistSuccess
} from '../actions/playlists'
import { selectPlaylists, selectPlaylistById } from '../selectors/playlists'
import { PlaylistsById } from '../types/playlists'
import { RecurringRuleFormValue, ChannelItemConditionsFormValue } from '../types/channels'
import { runTour } from '../actions/tours'
import { Tour } from '../types/tours'
import { channelDeletionFail } from '../utils/message'
import { CampaignsById } from '../types/states'
import { selectCampaigns } from '../selectors/campaigns'
import { deselectCampaign, getCampaign } from '../actions/campaigns'
import {
  DeleteChannelItemAction,
  DeleteChannelSuccessAction,
  ListChannelsAction,
  OpenChannelsDialogAction,
  OpenEditChannelItemAction
} from '../types/actions'

/** If noEndDate is selected in form, return only freq and interval */
const getRecurringRuleFormValue = (
  recurringRule?: RecurringRuleFormValue | null
): RecurringRule | undefined => {
  if (recurringRule) {
    const { noEndDate, freq, interval, endDate } = recurringRule
    return noEndDate
      ? {
          freq,
          interval
        }
      : {
          freq,
          interval,
          endDate
        }
  }
  return undefined
}

const getConditionsFormValue = (conditions?: ChannelItemConditionsFormValue) => {
  if (conditions) {
    const newConditions: ChannelItemConditions = {
      ...(conditions.operator ? { operator: conditions.operator } : {}),
      ...(conditions.tags ? { tags: conditions.tags.map(tag => tag.value) } : {}),
      ...(conditions.screens
        ? {
            screens: conditions.screens.map(({ data: { code, environmentId, screenId } }) => ({
              code,
              environmentId,
              screenId
            }))
          }
        : {})
    }
    return newConditions
  }
  return null
}

export function* handleCreateChannel({
  payload: { formData, resolve, reject }
}: handleCreateChannelParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const {
      name,
      permissionTags: formPermissionTags,
      tags: formTags,
      syncPlay,
      permissions,
      defaultPlaylistInterval
    } = formData
    const tags = formTags && formTags.length ? formTags.map(({ value }) => value) : null
    const permissionTags =
      formPermissionTags && formPermissionTags.length
        ? formPermissionTags.map(({ value }) => value)
        : null
    if (environmentId) {
      const channel: Channel = yield call(Api.createChannel, environmentId, {
        name,
        tags,
        permissionTags,
        syncPlay,
        permissions,
        defaultPlaylistInterval
      })
      yield put(createChannelSuccess(channel))
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    yield put(createChannelFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.channel.somethingWrongCreate')
      })
    )
  }
}

export function* handleCreateChannelItem({
  payload: { formData, resolve, reject }
}: handleCreateChannelItemsParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const channelId: string | undefined = yield select(selectContentIdFromPathname)
    if (environmentId && channelId) {
      const playlistsById: PlaylistsById = yield select(selectPlaylists)
      const campaignsById: CampaignsById = yield select(selectCampaigns)
      const channelItems: ChannelItem[] = yield select(
        selectChannelItemsByChannelIdAsArray(channelId)
      )
      const {
        channelItem: {
          start,
          end,
          startTime,
          endTime,
          playlistId: existingPlaylist,
          recurringRule,
          recurringEvent,
          priority,
          conditions
        },
        playlist: formPlaylist,
        isNewPlaylist
      } = formData
      const isCollapsing = checkIfSlotOverlapsTooManyCalendarEvents({
        events: convertChannelItemsToEvents(channelItems, playlistsById, campaignsById),
        start,
        end,
        recurringRule: recurringEvent ? recurringRule : undefined
      })
      let playlistId
      if (isCollapsing) {
        throw new SubmissionError({
          _error: i18n.t('channels.calendar.collapsesPlaylist', {
            maxOverlappingPlaylistsLimit: MAX_OVERLAPPING_PLAYLISTS_LIMIT
          })
        })
      }
      // Create new playlist and add it to the channel as item
      if (isNewPlaylist && formPlaylist?.name && formPlaylist?.defaultInterval) {
        const playlist: Playlist = yield call(PlaylistsApi.createPlaylist, environmentId, {
          name: formPlaylist.name,
          defaultInterval: formPlaylist.defaultInterval,
          permissionTags:
            formPlaylist.permissionTags && formPlaylist.permissionTags.map(({ value }) => value),
          syncPlay: formPlaylist.syncPlay
        })
        yield put(createPlaylistSuccess(playlist))
        playlistId = playlist.playlistId
      } else {
        playlistId = existingPlaylist.value
      }

      const channelItemFormData: ChannelItemFormData = {
        playlistId,
        start: getScheduleDateTimeFromDate(start),
        end: getScheduleDateTimeFromDate(end),
        startTime: startTime ? getScheduleTimeFromString(startTime.toISOString()) : null,
        endTime: endTime ? getScheduleTimeFromString(endTime.toISOString()) : null,
        recurringRule: recurringEvent ? getRecurringRuleFormValue(recurringRule) : null,
        priority,
        conditions: getConditionsFormValue(conditions)
      }

      const newChannelItem: ChannelItem = yield call(
        ChannelItemsApi.createChannelItem,
        environmentId,
        channelId,
        channelItemFormData
      )
      yield put(createChannelItemSuccess({ channelId, item: newChannelItem }))
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    yield put(createChannelItemFail(error.message))
    if (error instanceof SubmissionError) {
      yield call(reject, new SubmissionError({ _error: path(['errors', '_error'], error) }))
    } else {
      yield call(
        reject,
        new SubmissionError({ _error: i18n.t('error.channel.somethingWrongCreate') })
      )
    }
  }
}

export function* handleUpdateChannelItem({
  payload: { formData, resolve, reject }
}: handleUpdateChannelItemsParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const channelId: string | undefined = yield select(selectContentIdFromPathname)
    if (environmentId && channelId) {
      const channelItems: ChannelItem[] = yield select(
        selectChannelItemsByChannelIdAsArray(channelId)
      )
      const playlistsById: PlaylistsById = yield select(selectPlaylists)
      const campaignsById: CampaignsById = yield select(selectCampaigns)
      const events = convertChannelItemsToEvents(channelItems, playlistsById, campaignsById)
      const {
        channelItem: {
          start,
          end,
          startTime,
          endTime,
          playlistId,
          recurringRule,
          recurringEvent,
          priority,
          conditions
        },
        playlist,
        itemId
      } = formData
      const isCollapsing = checkIfSlotOverlapsTooManyCalendarEvents({
        events,
        start,
        end,
        recurringRule,
        skipItemId: itemId
      })
      if (isCollapsing) {
        throw new SubmissionError({
          _error: i18n.t('channels.calendar.collapsesPlaylist', {
            maxOverlappingPlaylistsLimit: MAX_OVERLAPPING_PLAYLISTS_LIMIT
          })
        })
      }
      const { defaultInterval: dI, permissionTags: pT, name: n } = yield select(
        selectPlaylistById(playlistId.value)
      )
      const existingPlaylistProps = {
        defaultInterval: dI,
        permissionTags: pT,
        name: n
      }

      // If playlist properties changed, update it.
      if (!isEqual(existingPlaylistProps, playlist)) {
        const { defaultInterval, permissionTags, name } = playlist
        const updatedPlaylist: PlaylistUI = yield call(
          PlaylistsApi.updatePlaylist,
          environmentId,
          playlistId.value,
          {
            name,
            defaultInterval,
            permissionTags: permissionTags ? permissionTags.map(({ value }) => value) : null
          }
        )
        yield put(updatePlaylistSuccess(updatedPlaylist))
      }

      const channelItemFormData: ChannelItemFormData = {
        playlistId: path(['value'], playlistId) as string,
        start: getScheduleDateTimeFromDate(start),
        end: getScheduleDateTimeFromDate(end),
        startTime: startTime ? getScheduleTimeFromString(startTime.toISOString()) : null, // Set as 'null' when not used
        endTime: endTime ? getScheduleTimeFromString(endTime.toISOString()) : null, // Set as 'null' when not used
        recurringRule: recurringEvent ? getRecurringRuleFormValue(recurringRule) : null,
        priority,
        conditions: getConditionsFormValue(conditions)
      }

      const updatedChannelItem: ChannelItem = yield call(
        ChannelItemsApi.updateChannelItem,
        environmentId,
        channelId,
        itemId,
        channelItemFormData
      )
      yield put(updateChannelItemSuccess({ channelId, item: updatedChannelItem }))
      toast.success(i18n.t('channels.actions.updateSuccess'))
      resolve()
    }
  } catch (error) {
    yield put(updateChannelItemFail(error.message))
    if (error instanceof SubmissionError) {
      yield call(reject, new SubmissionError({ _error: path(['errors', '_error'], error) }))
    } else {
      yield call(
        reject,
        new SubmissionError({ _error: i18n.t('error.channel.somethingWrongUpdate') })
      )
    }
  }
}

export function* handleUpdateChannel({
  payload: { formData, resolve, reject }
}: handleUpdateChannelParams) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const {
      name,
      channelId,
      permissionTags: formPermissionTags,
      tags: formTags,
      permissions,
      defaultPlaylistInterval
    } = formData
    const tags = formTags && formTags.length ? formTags.map(({ value }) => value) : null
    const permissionTags =
      formPermissionTags && formPermissionTags.length
        ? formPermissionTags.map(({ value }) => value)
        : null
    if (environmentId) {
      const updatedChannel: Channel = yield call(Api.updateChannel, environmentId, channelId, {
        name,
        tags,
        permissionTags,
        permissions,
        defaultPlaylistInterval
      })
      yield put(updateChannelSuccess(updatedChannel))
      yield put(closeDialog())
      resolve()
    }
  } catch (error) {
    yield put(updateChannelFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.channel.somethingWrongUpdate')
      })
    )
  }
}

export function* handleDeleteChannelItem({ payload: itemId }: DeleteChannelItemAction) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const channelId: string | undefined = yield select(selectContentIdFromPathname)
    if (environmentId && channelId && itemId) {
      yield call(ChannelItemsApi.deleteChannelItem, environmentId, channelId, itemId)
      yield put(deselectPlaylist())
      yield put(deselectCampaign())
      yield put(closeEditChannelItem())
      yield put(closeDialog())
      yield put(deleteChannelItemSuccess({ channelId, itemId }))
    }
  } catch (error) {
    toast.error(i18n.t('error.channel.somethingWrongDeleteChannelItem'))
    yield put(deleteChannelItemFail(error.message))
  }
}

export function* handleDeleteChannel({ payload: channelId }: DeleteChannelSuccessAction) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      yield call(Api.deleteChannel, environmentId, channelId)
      yield put(deleteChannelSuccess(channelId))
      yield put(closeDialog())
    }
  } catch (error) {
    const screens: string[] | undefined = path(['response', 'data', 'screens'], error)
    const errorMessage = screens ? channelDeletionFail(screens) : undefined
    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: 10000
      })
    }
    yield put(deleteChannelFail(error.message))
  }
}

export function* handleListChannels({ payload }: ListChannelsAction) {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const channelsArray: Channel[] = yield call(
        Api.getChannels,
        environmentId,
        payload?.includeParentChannels
      )
      yield put(listChannelsSuccess({ environmentId, channelsArray }))
    }
  } catch (error) {
    yield put(listChannelsFail(error.message))
  }
}

export function* handleGetChannel() {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const channelId: string | undefined = yield select(selectContentIdFromPathname)
    if (environmentId && channelId) {
      const channelResponse: Channel = yield call(Api.getChannel, environmentId, channelId)
      yield put(getChannelSuccess(channelResponse))
    } else {
      throw new Error('environmentId or channelId missing')
    }
  } catch (error) {
    yield put(getChannelFail(error.message))
  }
}

export function* handleListChannelItems() {
  try {
    const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
    const channelId: string | undefined = yield select(selectContentIdFromPathname)
    if (environmentId && channelId) {
      const channelItemsResponse: ChannelItem[] = yield call(
        ChannelItemsApi.listChannelItems,
        environmentId,
        channelId
      )
      yield put(listChannelItemsSuccess({ channelId, channelItemsArray: channelItemsResponse }))
    } else {
      throw new Error('environmentId or channelId missing')
    }
  } catch (error) {
    yield put(listChannelItemsFail(error.message))
  }
}

function* handleOpenChannelsDialog({ payload: { id } }: OpenChannelsDialogAction) {
  yield put(closeEditChannelItem())
  yield put(openDialog(id))
}

function* handleOpenEditChannelItem({ payload: { event } }: OpenEditChannelItemAction) {
  const playlistId = event.playlistId
  const campaignId = event.campaignId
  if (playlistId) {
    yield put(getPlaylist(playlistId))
    // run create playlist item tour after all required actions are ready.
    yield take([getPlaylistSuccess])
    yield put(runTour(Tour.createPlaylistItem))
  } else if (campaignId) {
    yield put(getCampaign(campaignId))
  }
}

function* watchChannelsActions() {
  yield all([
    takeLatest(createChannel, handleCreateChannel),
    takeLatest(createChannelItem, handleCreateChannelItem),
    takeLatest(updateChannelItem, handleUpdateChannelItem),
    takeLatest(deleteChannelItem, handleDeleteChannelItem),
    takeLatest(updateChannel, handleUpdateChannel),
    takeLatest(deleteChannel, handleDeleteChannel),
    takeLatest(listChannels, handleListChannels),
    takeLatest(getChannel, handleGetChannel),
    takeLatest(listChannelItems, handleListChannelItems),
    takeLatest(openChannelsDialog, handleOpenChannelsDialog),
    takeLatest(openEditChannelItem, handleOpenEditChannelItem)
  ])
}

export default [watchChannelsActions]
