import { Coordinates, Location } from '@seesignage/seesignage-utils'
import config from '../../config/google'
import { getApiUrlLanguage } from '../../utils/language'

const getPlacesApiUrl = () => {
  const lang = getApiUrlLanguage()
  return `https://maps.googleapis.com/maps/api/js?key=
${config.googleMaps.places.API_KEY}&libraries=places&
language=${lang}`
}

const getLocationInfo = async (placeId: string) => {
  const geometry = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?place_id=
${placeId}&key=${config.googleMaps.places.API_KEY}`
  ).then(response => response.json())
  // do fail safe when location information is not found for placeId and let user know why it failed
  return geometry.results[0].geometry.location as Coordinates
}

export interface TimeZoneResponse {
  dstOffset: number
  rawOffset: number
  status: string
  timeZoneId: string
  timeZoneName: string
}

const getTimeZone = async ({ lat, lng }: Coordinates) => {
  const timestampAsSeconds = Date.now() / 1000
  const geometry = await fetch(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestampAsSeconds}&key=${config.googleMaps.places.API_KEY}`
  ).then(response => response.json())
  return geometry as TimeZoneResponse
}

const getLocationForScreen = async (placeId: string, label: string) => {
  const coordinates = await getLocationInfo(placeId)
  const { timeZoneId } = await getTimeZone(coordinates)
  const location: Location = {
    id: placeId,
    coordinates,
    label,
    timeZone: timeZoneId
  }
  return location
}

export { getLocationInfo, getPlacesApiUrl, getTimeZone, getLocationForScreen }
