import { SubmissionError } from 'redux-form'
import { toast } from 'react-toastify'
import { path } from 'ramda'
import { all, take, takeLatest, put, call, select } from 'redux-saga/effects'
import {
  Environment,
  SubEnvironments,
  UserRole,
  EnvironmentUI,
  User
} from '@seesignage/seesignage-utils'
import {
  createEnvironment,
  createEnvironmentSuccess,
  createEnvironmentFail,
  listEnvironments,
  listEnvironmentsSuccess,
  listEnvironmentsFail,
  getEnvironment,
  getEnvironmentSuccess,
  getEnvironmentFail,
  updateEnvironmentFail,
  deleteEnvironment,
  deleteEnvironmentSuccess,
  deleteEnvironmentFail,
  editEnvironmentPermissions,
  editEnvironmentPermissionsSuccess,
  editEnvironmentPermissionsFail,
  checkEmailIsUnique,
  addEnvironmentUsersSuccess,
  addEnvironmentUsers,
  editEnvironment,
  editEnvironmentSuccess,
  editEnvironmentFail,
  updateSubEnvironments,
  updateSubEnvironmentsSuccess,
  updateSubEnvironmentsFail,
  removeAsSubEnvironmentSuccess,
  removeAsSubEnvironmentFail,
  removeAsSubEnvironment,
  removeEnvironmentUserSuccess,
  removeEnvironmentUserFail,
  removeEnvironmentUser,
  updateAdditionalEnvironmentSettings,
  updateAdditionalEnvironmentSettingsSuccess,
  updateAdditionalEnvironmentSettingsFail,
  deselectEnvironment
} from '../actions/environments'
import { closeDialog } from '../actions/dialogs'
import Api from '../services/api/environments'
import { listConfirmations } from '../actions/confirmations'
import {
  selectEnvironmentById,
  selectHyperEnvironmentIdBySubId,
  selectEnvironmentsAsArray,
  selectSelectedEnvironment
} from '../selectors/environments'
import i18n from '../translations/i18n'
import {
  updatePermissions,
  initializeSessionSuccess,
  initializeSessionFail
} from '../actions/users'
import { selectUser } from '../selectors/users'
import { compareStrings } from '../utils/sorting'
import {
  handleCreateEnvironmentFormParams,
  handleAddusersToEnvironmentFormParams,
  handleEditEnvironmentPermissionsFormParams,
  handleEditEnvironmentFormParams,
  handleEditSubEnvironmentsFormParams,
  handleUpdateAdditionalEnvironmentSettingsParams
} from '../types/formData'
import { InitialEnvironmentUser, GetEnvironment } from '../types/environments'
import { UserInterface } from '../types/users'
import { selectEnvironmentIdFromPathname } from '../selectors/routing'
import { getSelectedEnvironmentIdLocalStorage } from '../services/localStorage/environments'
import { navigateToEnvironment } from '../actions/routes'

export function* handleCreateEnvironment({
  payload: { formData, resolve, reject }
}: handleCreateEnvironmentFormParams) {
  try {
    const environment: Environment = yield call(Api.createEnvironment, formData)
    yield put(createEnvironmentSuccess(environment))
    yield put(closeDialog())
    yield put(navigateToEnvironment({ environmentId: environment.environmentId }))
    resolve()
  } catch (error) {
    const errorMessage = path(['response', 'data', 'message'], error)
    if (errorMessage) {
      yield call(
        reject,
        new SubmissionError({
          _error: `${i18n.t('error.environment.couldNotCreate')}`
        })
      )
    } else {
      yield call(
        reject,
        new SubmissionError({
          _error: i18n.t('error.environment.somethingWrongCreate')
        })
      )
    }
    yield put(createEnvironmentFail(error.message))
  }
}

export function* handleRemoveEnvironmentUser({ payload: userId }: { payload: string }) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(Api.removeUser, environmentId, userId)
    yield put(getEnvironment({ environmentId }))
    // it is possible that confirmation were updated when updating environment
    yield put(listConfirmations(environmentId))
    yield put(closeDialog())
    yield put(removeEnvironmentUserSuccess())
  } catch (error) {
    yield put(removeEnvironmentUserFail(error.message))
  }
}

export function* handleAddUsersToEnvironment({
  payload: { formData, resolve, reject }
}: handleAddusersToEnvironmentFormParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(Api.addUsers, environmentId, formData)
    yield put(addEnvironmentUsersSuccess())
    yield put(getEnvironment({ environmentId }))
    // it is possible that confirmation were updated when updating environment
    yield put(listConfirmations(environmentId))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.environment.somethingWrongUpdate')
      })
    )
    yield put(updateEnvironmentFail(error.message))
  }
}

function* updateUserPermissions(user: UserInterface, environment?: Environment) {
  if (user.role === UserRole.user) {
    const environmentUser = environment
      ? environment.users.find(u => u.sub === user.sub)
      : undefined
    yield put(
      updatePermissions({ permissions: environmentUser?.permissions, role: environmentUser?.role })
    )
  }
}

export function* handleListEnvironments() {
  try {
    const environments: Environment[] = yield call(Api.getEnvironments)
    yield put(listEnvironmentsSuccess(environments))
  } catch (error) {
    yield put(listEnvironmentsFail(error.message))
  }
}

interface HandleGetEnvironmentParams {
  payload: {
    environmentId: string
  }
}

/**
 * Get environment and set it as selectedEnvironment to store
 */
export function* handleGetEnvironment({ payload: { environmentId } }: HandleGetEnvironmentParams) {
  try {
    let user: UserInterface = yield select(selectUser)
    if (!user) {
      // if user not in state, wait for initializeSession to finish before getting environment
      yield take([initializeSessionSuccess, initializeSessionFail])
      user = yield select(selectUser)
    }

    const environmentResponse: GetEnvironment = yield call(Api.getEnvironment, environmentId)
    const { environment, templateTypes } = environmentResponse
    yield call(updateUserPermissions, user, environment)
    yield put(getEnvironmentSuccess({ environment, templateTypes }))
  } catch (error) {
    yield put(getEnvironmentFail(error.message))
  }
}

interface HandleDeleteEnvironmentParams {
  payload: string
}

export function* handleDeleteEnvironment({
  payload: environmentId
}: HandleDeleteEnvironmentParams) {
  try {
    yield call(Api.deleteEnvironment, environmentId)
    yield put(deleteEnvironmentSuccess(environmentId))
    const environments: Environment[] = yield select(selectEnvironmentsAsArray)
    environments.sort(compareStrings('name'))
    // navigate to first environment of list
    yield put(navigateToEnvironment({ environmentId: environments[0].environmentId }))
    yield put(closeDialog())
  } catch (error) {
    const errorMessage: string | undefined = path(['response', 'data', 'message'], error)
    if (errorMessage) {
      toast.error(errorMessage, {
        autoClose: 10000
      })
    }
    yield put(deleteEnvironmentFail(error.message))
  }
}

export function* handleEditEnvironmentPermissions({
  payload: { formData, resolve, reject }
}: handleEditEnvironmentPermissionsFormParams) {
  try {
    const {
      permissions: { lists, templates, tags },
      role,
      sub,
      environmentId
    } = formData
    const permissionTags = tags ? tags.map(tag => tag.value) : null
    const permissions = {
      role,
      sub,
      permissions: {
        lists,
        templates,
        tags: permissionTags
      }
    }
    const updatedUsers: User[] = yield call(
      Api.updateEnvironmentPermissions,
      permissions,
      environmentId
    )
    yield put(closeDialog())
    yield put(getEnvironment({ environmentId }))
    yield put(editEnvironmentPermissionsSuccess({ environmentId, updatedUsers }))
    resolve()
  } catch (error) {
    yield put(editEnvironmentPermissionsFail(error.message))
    reject(error)
  }
}

interface HandleCheckIsEmailUniqueParams {
  payload: {
    users: InitialEnvironmentUser[]
    resolve: () => void
    reject: (error: any) => void
  }
}

export function* handleCheckIsEmailUnique({
  payload: { users, resolve, reject }
}: HandleCheckIsEmailUniqueParams) {
  try {
    const { users: existingUsers }: EnvironmentUI = yield select(selectSelectedEnvironment)
    const existingEmails = existingUsers.map(({ email }) => email)
    users.map(({ email }, index) => {
      if (existingEmails.includes(email)) {
        // @ts-ignore
        throw new Error(index)
      }
      return email
    })
    resolve()
  } catch (error) {
    yield call(reject, {
      users: { [error.message]: { email: i18n.t('validation.emailAlreadyExists') } }
    })
  }
}

export function* handleEditEnvironment({
  payload: { formData, resolve, reject }
}: handleEditEnvironmentFormParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { name, displayName, features, pricing, colors, notes } = formData
    const updatedAttributes: EnvironmentUI = yield call(
      Api.updateEnvironmentSettings,
      environmentId,
      {
        name,
        displayName,
        features,
        pricing,
        colors,
        notes
      }
    )
    yield put(closeDialog())
    yield put(getEnvironment({ environmentId }))
    yield put(editEnvironmentSuccess({ environmentId, updatedAttributes }))
    resolve()
  } catch (error) {
    yield put(editEnvironmentFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.environment.updateSettingsFail')
      })
    )
  }
}

export function* handleUpdateAdditionalSettings({
  payload: { formData, resolve, reject }
}: handleUpdateAdditionalEnvironmentSettingsParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { tags, displayName } = formData
    const updatedAttributes: EnvironmentUI = yield call(
      Api.updateAdditionalEnvironmentSettings,
      environmentId,
      {
        displayName,
        tags: tags.map(({ value }) => value)
      }
    )
    yield put(closeDialog())
    yield put(getEnvironment({ environmentId }))
    yield put(editEnvironmentSuccess({ environmentId, updatedAttributes }))
    yield put(updateAdditionalEnvironmentSettingsSuccess())
    resolve()
  } catch (error) {
    yield put(updateAdditionalEnvironmentSettingsFail(error.message))
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.environment.updateSettingsFail')
      })
    )
  }
}

export function* handleUpdateHyperEnvironmentSubs({
  payload: { formData, resolve, reject }
}: handleEditSubEnvironmentsFormParams) {
  try {
    const { environmentId, selectedSubEnvironments } = formData
    const subEnvironments = selectedSubEnvironments
      ? selectedSubEnvironments.reduce((subs: SubEnvironments, { environmentId }) => {
          subs[environmentId] = environmentId
          return subs
        }, {})
      : {}
    const { updatedEnvironments } = yield call(
      Api.updateEnvironmentDependency,
      environmentId,
      subEnvironments
    )
    yield put(getEnvironment({ environmentId }))
    yield put(updateSubEnvironmentsSuccess(updatedEnvironments))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield put(updateSubEnvironmentsFail(error.message))
    reject(error)
  }
}

interface HandleRemoveAsSubEnvironmentParams {
  payload: string
}

/**
 * Handler function to unlink sub environment from it's parent
 * @param param0
 */
export function* handleRemoveAsSubEnvironment({
  payload: environmentId
}: HandleRemoveAsSubEnvironmentParams) {
  try {
    const hyperEnvironmentId: string | undefined = yield select(
      selectHyperEnvironmentIdBySubId(environmentId)
    )
    const hyperEnvironment: Environment | undefined = yield select(
      selectEnvironmentById(hyperEnvironmentId)
    )
    if (hyperEnvironmentId && hyperEnvironment) {
      const { subEnvironments } = hyperEnvironment
      if (subEnvironments) {
        delete subEnvironments[environmentId]
        const { updatedEnvironments } = yield call(
          Api.updateEnvironmentDependency,
          hyperEnvironmentId,
          subEnvironments
        )
        yield put(removeAsSubEnvironmentSuccess(updatedEnvironments))
        yield put(getEnvironment({ environmentId: hyperEnvironmentId }))
        yield put(deselectEnvironment())
        yield put(closeDialog())
      } else {
        throw new Error('Environment has no sub environments')
      }
    } else {
      throw new Error(`No hyper environment found for environment ${environmentId}`)
    }
  } catch (error) {
    yield put(removeAsSubEnvironmentFail(error.message))
  }
}

export function* getSelectedEnvironmentId() {
  // first try to select environmentId from url path
  const environmentId: string | undefined = yield select(selectEnvironmentIdFromPathname)
  if (environmentId) {
    return environmentId
  }
  // if does not exist then get if it from local storage
  return getSelectedEnvironmentIdLocalStorage()
}

function* watchEnvironmentsActions() {
  yield all([
    takeLatest(createEnvironment, handleCreateEnvironment),
    takeLatest(removeEnvironmentUser, handleRemoveEnvironmentUser),
    takeLatest(listEnvironments, handleListEnvironments),
    takeLatest(getEnvironment, handleGetEnvironment),
    takeLatest(deleteEnvironment, handleDeleteEnvironment),
    takeLatest(editEnvironmentPermissions, handleEditEnvironmentPermissions),
    takeLatest(checkEmailIsUnique, handleCheckIsEmailUnique),
    takeLatest(addEnvironmentUsers, handleAddUsersToEnvironment),
    takeLatest(editEnvironment, handleEditEnvironment),
    takeLatest(updateAdditionalEnvironmentSettings, handleUpdateAdditionalSettings),
    takeLatest(updateSubEnvironments, handleUpdateHyperEnvironmentSubs),
    takeLatest(removeAsSubEnvironment, handleRemoveAsSubEnvironment)
  ])
}

export default [watchEnvironmentsActions]
