import { handleActions } from 'redux-actions'
import { Campaign, CampaignUI } from '@seesignage/seesignage-utils'
import { CampaignsState, IndexById } from '../types/states'
import {
  resetCampaigns,
  listCampaigns,
  listCampaignsFail,
  listCampaignsSuccess,
  createCampaignSuccess,
  deselectCampaign,
  getCampaignSuccess,
  publishCampaignSuccess,
  updateCampaignSuccess,
  acceptCampaignSuccess,
  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFail,
  prefillCampaignProductSuccess,
  prefillCampaignProductFail,
  prefillCampaignProduct,
  copyCampaignSuccess,
  setCurrentCampaignItemFieldName,
  clearCurrentCampaignItemFieldName,
  updateCampaignTemplateSuccess,
  getCampaign,
  getCampaignFail
} from '../actions/campaigns'
import { Action } from '../types/actions'
import { PrefillCampaignProductParams } from '../types/campaigns'

const initialState: CampaignsState = {
  campaigns: {},
  isLoading: false,
  isLoadingField: undefined,
  selectedCampaign: undefined,
  isDeleting: false
}

const campaignsReducer = handleActions<CampaignsState, any>(
  {
    [resetCampaigns]: () => initialState,
    [deselectCampaign]: state => ({
      ...state,
      selectedCampaign: undefined
    }),
    [getCampaign]: state => ({
      ...state,
      isLoading: true
    }),
    [getCampaignFail]: state => ({
      ...state,
      isLoading: false
    }),
    [getCampaignSuccess]: (state, { payload: campaign }: Action<CampaignUI>) => ({
      ...state,
      selectedCampaign: campaign,
      isLoading: false
    }),
    [listCampaigns]: state => ({
      ...state,
      isLoading: true
    }),
    [listCampaignsFail]: state => ({
      ...state,
      isLoading: false
    }),
    [listCampaignsSuccess]: (state, { payload: campaignsArray }: Action<Campaign[]>) => {
      const campaigns = campaignsArray.reduce((campaigns: IndexById<Campaign>, campaign) => {
        campaigns[campaign.campaignId] = {
          ...campaign
        }
        return campaigns
      }, {})
      return {
        ...state,
        campaigns,
        isLoading: false
      }
    },
    [createCampaignSuccess]: (state, { payload: campaign }: Action<Campaign>) => ({
      ...state,
      campaigns: {
        ...state.campaigns,
        [campaign.campaignId]: campaign
      }
    }),
    [copyCampaignSuccess]: (state, { payload: campaign }: Action<Campaign>) => ({
      ...state,
      campaigns: {
        ...state.campaigns,
        [campaign.campaignId]: campaign
      }
    }),
    [publishCampaignSuccess]: state => ({
      ...state,
      selectedCampaign: state.selectedCampaign
        ? { ...state.selectedCampaign, published: true }
        : undefined
    }),
    [acceptCampaignSuccess]: state => ({
      ...state,
      selectedCampaign: state.selectedCampaign
        ? { ...state.selectedCampaign, accepted: true }
        : undefined
    }),
    [updateCampaignSuccess]: (state, { payload: campaign }: Action<Campaign>) => {
      const existingItems = state.selectedCampaign ? [...state.selectedCampaign.items] : []
      return {
        ...state,
        selectedCampaign: {
          ...campaign,
          items: existingItems
        }
      }
    },
    [updateCampaignTemplateSuccess]: (state, { payload: campaign }: Action<Campaign>) => {
      const existingItems = state.selectedCampaign ? [...state.selectedCampaign.items] : []
      return {
        ...state,
        selectedCampaign: {
          ...campaign,
          items: existingItems
        }
      }
    },
    [deleteCampaign]: state => ({
      ...state,
      isDeleting: true
    }),
    [deleteCampaignSuccess]: (state, { payload: campaignId }: Action<string>) => {
      const campaigns = { ...state.campaigns }
      delete campaigns[campaignId]
      return {
        ...state,
        campaigns,
        isDeleting: false
      }
    },
    [deleteCampaignFail]: state => ({
      ...state,
      isDeleting: false
    }),
    [prefillCampaignProduct]: (
      state,
      { payload: { fieldName } }: Action<PrefillCampaignProductParams>
    ) => ({
      ...state,
      isLoadingField: fieldName
    }),
    [prefillCampaignProductSuccess]: state => ({
      ...state,
      isLoadingField: undefined
    }),
    [prefillCampaignProductFail]: state => ({
      ...state,
      isLoadingField: undefined
    }),
    [setCurrentCampaignItemFieldName]: (state, { payload }: Action<string>) => ({
      ...state,
      currentItemFieldName: payload
    }),
    [clearCurrentCampaignItemFieldName]: state => ({
      ...state,
      currentItemFieldName: undefined
    })
  },
  initialState
)

export default campaignsReducer
