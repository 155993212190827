import {
  Environment,
  GeneralRetailProductProperty,
  PricerProductProperty,
  EnvironmentUserRole,
  PharmacyProductProperty,
  Timer,
  AdditionalPlayerItemProperty
} from '@seesignage/seesignage-utils'
import i18n from '../translations/i18n'
import { AddUsersToEnvironmentFormDataBeforeValidation } from '../types/environments'

const MAX_CHARS_IN_FILE_NAME = 255

const required = (value: any) =>
  value || typeof value === 'number' ? undefined : i18n.t('validation.required')

const volume = (value: number | null) => {
  if (value === null) {
    return undefined
  }
  return value >= 0 && value <= 100 ? undefined : i18n.t('validation.volume')
}

const requiredWithoutMessage = (value: any) => (value ? undefined : ' ')

/** Phone number without dialing code. */
const phoneNumber = (value?: string) => {
  if (value) {
    // check if empty spaces
    if (/\s/g.test(value)) {
      return i18n.t('validation.phoneNoSpaces')
    }
    // make sure that only digits
    if (!/^\d+$/.test(value)) {
      return i18n.t('validation.onlyDigits')
    }
    // leading zero not allowed
    if (value.length > 0 && value[0] === '0') {
      return i18n.t('validation.phoneNoLeadingZero')
    }
  }
}

const email = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? i18n.t('validation.invalidEmail')
    : undefined

const password = (value: string) =>
  value && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(value)
    ? undefined
    : i18n.t('validation.pwComplexity')

const passwordsMatch = (value: string, allValues: any) =>
  value !== allValues.password ? i18n.t('validation.pwNoMatch') : undefined

const adminRequired = (formData: any, { users }: Environment) =>
  !users.some(u => u.role === EnvironmentUserRole.admin)
    ? i18n.t('validation.adminRequired')
    : undefined

/**
 * Check if filename is invalid
 * \p{L} matches any kind of letter from any language
 * \d matches a digit (equivalent to [0-9])
 */
const isInvalidFilename = (name: string) => /[^\p{L}\d .-_()?!–—-]\p{M}+/u.test(name)

const isTooLongFilename = (name: string) => name.length > MAX_CHARS_IN_FILE_NAME

const defaultInterval = (interval?: number) =>
  interval === undefined || interval > 0 ? undefined : i18n.t('validation.defaultInterval')

const customInterval = (interval: number) =>
  interval > 0 ? undefined : i18n.t('validation.interval')

const isPriceValid = (price: number) =>
  price && price < 0 ? i18n.t('validation.priceNegative') : undefined

const eanOrPlu = (input: string) =>
  input && (input.length === 4 || input.length === 13) && /^[0-9]*$/.test(input)
    ? undefined
    : i18n.t('validation.invalidEan')

const emailsUnique = (
  emailValue: string,
  formValues: AddUsersToEnvironmentFormDataBeforeValidation
) => {
  if (!emailValue || !formValues?.users) {
    return undefined // No validation if email or users are missing
  }

  // Normalize the input email
  const normalizedEmail = emailValue.trim().toLowerCase()

  // Count occurrences of the email in the form's users array
  const emailOccurrences = formValues.users.filter(
    user => user?.email?.trim().toLowerCase() === normalizedEmail
  ).length

  // Return validation message if duplicates exist
  if (emailOccurrences > 1) {
    return i18n.t('validation.duplicateEmails')
  }
}

const validUrl = (url: string) => {
  const regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ //eslint-disable-line
  return regex.test(url) ? undefined : i18n.t('validation.invalidUrl')
}

const validFalconyAnnouncementsDomain = (url: string) => {
  const regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.pelsu.fi\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/ //eslint-disable-line
  return regex.test(url) ? undefined : i18n.t('validation.invalidFalconyAnnouncementsDomain')
}

const validNewFileName = (fileName: string) =>
  isInvalidFilename(fileName) ? i18n.t('validation.containsAllowedCharacters') : undefined

const isNumber = (input: string) => /^\d+$/.test(input)

const greaterThanZero = (value: any) =>
  value > 0 ? undefined : i18n.t('validation.mustBeGreaterThan', { min: 0 })

const greaterThanZeroOrEmpty = (value: any) =>
  typeof value === 'number' && value <= 0
    ? i18n.t('validation.mustBeGreaterThan', { min: 0 })
    : undefined

const maxValue = (max: number) => (value: any) =>
  typeof value === 'number' && value > max ? i18n.t('validation.maxValue', { max }) : undefined

const minValue = (min: number) => (value: any) =>
  typeof value === 'number' && value < min ? i18n.t('validation.minValue', { min }) : undefined

const maxItems = (max: number) => (value: any) =>
  value && value.length > max ? i18n.t('validation.maxItemAmount', { max }) : undefined

const minItems = (min: number) => (value: any) =>
  value && value.length < min ? i18n.t('validation.minItemAmount', { min }) : undefined

/**
 * Validation function that checks that custom value field has correct product properties
 */
const validateTemplateCustomValue = (value?: string) => {
  if (value && value.length > 0) {
    const properties = value.match(/%[^%]*%/g)
    if (properties) {
      for (const property of properties) {
        const replaced = property.replace(/%/g, '')
        if (
          replaced in GeneralRetailProductProperty ||
          replaced in PricerProductProperty ||
          replaced in PharmacyProductProperty ||
          replaced in AdditionalPlayerItemProperty
        ) {
          continue
        } else {
          return `${replaced} is not a valid product property`
        }
      }
    } else {
      return 'Missing product property'
    }
  }
  return undefined
}

/**
 * Validation function that checks that custom value field has value
 */
const validateTemplateCustomValueExist = (value?: string) => {
  if (value && value.length > 0) {
    const properties = value.match(/%[^%]*%/g)
    if (properties && properties.length === 0) {
      return 'Missing product property'
    }
  }
  return undefined
}

const isNotEmptyList = (list: any[] | undefined | null) => {
  return list && list.length > 0 ? undefined : i18n.t('validation.isNotEmptyList')
}
const isPositiveNumber = (number: number) =>
  number >= 0 ? undefined : i18n.t('validation.isPositiveNumber')

const maxStorageSize = (value: any) => {
  return typeof value === 'number' && value > 100
    ? i18n.t('validation.maxNumberValue', { max: 100 })
    : undefined
}

const validateYoutubeUrl = (url: string) => {
  if (
    url.startsWith('https://www.youtube.com/') &&
    !url.startsWith('https://www.youtube.com/embed')
  ) {
    return i18n.t('validation.useYoutubeEmbed')
  }
  return undefined
}

const validateNonEmptyObject = (val: any) =>
  val && Object.keys(val).length > 0 ? undefined : i18n.t('validation.selectOption')

const validateOnOffTimers = (errors: any, values: any) => {
  if (values) {
    const onTimers: Timer[] | undefined = values.configs?.onOffTimer?.onTimers
    const offTimers: Timer[] | undefined = values.configs?.onOffTimer?.offTimers
    const offTimerMissing =
      onTimers &&
      onTimers.length > 0 &&
      (offTimers === undefined || (offTimers && offTimers.length === 0))
    const onTimerMissing =
      offTimers &&
      offTimers.length > 0 &&
      (onTimers === undefined || (onTimers && onTimers.length === 0))

    if (offTimerMissing || onTimerMissing) {
      // ON or OFF timer missing
      errors = {
        ...errors,
        configs: {
          ...errors.configs,
          onOffTimer: {
            ...errors.configs?.onOffTimer,
            onTimers: { _error: i18n.t('validation.bothOnAndOffTimerIsRequired') }
          }
        }
      }
    }
  }
  return errors
}

const validateCampaignProductName = (value?: string) =>
  value ? undefined : i18n.t('validation.campaignItemName')

export {
  required,
  volume,
  requiredWithoutMessage,
  phoneNumber,
  email,
  password,
  passwordsMatch,
  adminRequired,
  defaultInterval,
  customInterval,
  isPriceValid,
  eanOrPlu,
  emailsUnique,
  validUrl,
  validNewFileName,
  isNumber,
  maxItems,
  minItems,
  validateTemplateCustomValue,
  validateTemplateCustomValueExist,
  isNotEmptyList,
  isPositiveNumber,
  maxStorageSize,
  validateYoutubeUrl,
  validateNonEmptyObject,
  validateOnOffTimers,
  validateCampaignProductName,
  greaterThanZero,
  isInvalidFilename,
  isTooLongFilename,
  maxValue,
  minValue,
  greaterThanZeroOrEmpty,
  validFalconyAnnouncementsDomain
}
