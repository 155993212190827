import React, { Fragment, useState } from 'react'
import {
  Campaign,
  CampaignUI,
  CampaignSubUI,
  Customer,
  isParentCampaign,
  CampaignSub,
  isSubCampaign
} from '@seesignage/seesignage-utils'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import InfoIcon from 'mdi-react/InfoCircleIcon'
import {
  Grid,
  MenuItem,
  SwipeableDrawer,
  Tooltip,
  IconButton,
  Typography,
  Switch,
  Toolbar
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useHistory } from 'react-router'
import CloseIcon from 'mdi-react/CloseIcon'
import PageTitle from '../../components/PageTitle'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import { SortByValue, SortDirection } from '../../types/sortings'
import { StateInterface } from '../../types/states'
import { selectCampaignsAsArray, selectSelectedCampaign } from '../../selectors/campaigns'
import { bindSubmitActionToPromise } from '../../utils/forms'
import { createCampaign, deselectCampaign } from '../../actions/campaigns'
import { closeDialog } from '../../actions/dialogs'
import { CloseDialog, ForceRunTour } from '../../types/actions'
import Sorter from '../../components/Sorting'
import { selectQueryParamFromSearch } from '../../selectors/routing'
import { compareBySortType } from '../../utils/sorting'
import { selectRetailOrPharmacyCustomer } from '../../selectors/customers'
import { getCampaignInitialValues } from '../../utils/campaigns'
import { forceRunTour } from '../../actions/tours'
import { Tour } from '../../types/tours'
import { selectCurrentEnvironmentType } from '../../selectors/environments'
import { EnvironmentType } from '../../types/environments'
import CampaignsToolbar from './CampaignsToolbar'
import CampaignCard from './CampaignCard'
import EditCampaignForm from './EditCampaign/EditCampaignForm'
import EditCampaignButtons from './EditCampaign/EditCampaignButtons'

const useStyles = makeStyles(theme => ({
  campaigns: {
    padding: '10px 0px 10px',
    marginBottom: 100 // better mobile usability
  },
  drawerArea: {
    height: '100%'
  },
  editCampaignDrawer: {
    width: '70vw',
    [theme.breakpoints.down('xl')]: {
      width: '85vw'
    },
    [theme.breakpoints.down('xl')]: {
      width: '95vw'
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw'
    }
  },
  infoIcon: {
    position: 'absolute',
    right: 0
  },
  filters: {
    float: 'right',
    display: 'flex'
  },
  showOld: {
    paddingTop: 5
  }
}))

const filterAndSortCampaigns = (
  allCampaigns: (Campaign | CampaignSub)[],
  searchTerm: string,
  sortBy: SortByValue,
  sortDirection: SortDirection,
  showOld: boolean
) => {
  const currentDate = new Date()
  const visibleCampaigns =
    showOld === false || searchTerm.length > 0
      ? allCampaigns.filter(({ name, endDate }) => {
          const includesTerm = searchTerm.length > 0 ? name.includes(searchTerm) : true
          return !showOld ? new Date(endDate) > currentDate && includesTerm : false
        })
      : allCampaigns
  const campaigns = visibleCampaigns.sort(compareBySortType(sortBy, sortDirection))
  return campaigns
}

interface StateProps {
  allCampaigns: (Campaign | CampaignSub)[]
  selectedCampaign?: CampaignUI | CampaignSubUI
  pathCampaignId: string | null
  campaignCustomer?: Customer
  environmentType: EnvironmentType
}

interface DispatchProps {
  deselectCampaign: () => void
  createCampaign: (formData: any) => void
  closeDialog: CloseDialog
  forceRunTour: ForceRunTour
}

type CampaignsProps = StateProps & DispatchProps

const Campaigns: React.FC<CampaignsProps> = ({
  allCampaigns,
  closeDialog,
  deselectCampaign,
  createCampaign,
  selectedCampaign,
  pathCampaignId,
  campaignCustomer,
  forceRunTour,
  environmentType
}) => {
  const [t] = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const [showOld, setShowOld] = useState(false)
  const [searchTerm, search] = useState('')
  const [sortBy, setSortBy] = useState<SortByValue>(SortByValue.startDate)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.asc)
  const campaigns = filterAndSortCampaigns(allCampaigns, searchTerm, sortBy, sortDirection, showOld)
  const toolbarProps = {
    closeDialog,
    createCampaign,
    search,
    campaignCustomer,
    forceRunTour,
    environmentType
  }
  const sorterProps = {
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    t
  }
  const handleCloseDrawer = () => {
    history.replace({ search: undefined }) // remove queryparam
    deselectCampaign()
  }
  return (
    <Fragment>
      <PageTitle contentType={BreadcrumbContentType.campaigns} />
      <CampaignsToolbar {...toolbarProps} />
      <div className={classes.filters}>
        <div className={classes.showOld}>
          <Typography style={{ padding: 10 }} variant='caption'>
            {t('campaigns.showOld')}
          </Typography>
          <Switch color='primary' onChange={() => setShowOld(!showOld)} checked={showOld} />
        </div>
        <Sorter {...sorterProps}>
          <MenuItem value={SortByValue.startDate}>{t('sorting.sortBy.startDate')}</MenuItem>
          <MenuItem value={SortByValue.updatedAt}>{t('sorting.sortBy.updatedAt')}</MenuItem>
          <MenuItem value={SortByValue.name}>{t('sorting.sortBy.name')}</MenuItem>
        </Sorter>
      </div>
      <Grid
        container
        className={classes.campaigns}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}>
        {campaigns.length > 0 && (
          <Fragment>
            {campaigns.map(campaign => (
              <CampaignCard key={campaign.campaignId} campaign={campaign} />
            ))}
          </Fragment>
        )}
      </Grid>
      <SwipeableDrawer
        anchor='right'
        open={pathCampaignId ? true : false}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onOpen={() => {}}
        onClose={handleCloseDrawer}
        disableSwipeToOpen>
        <div className={classes.editCampaignDrawer} id='edit-campaign-drawer'>
          <Toolbar>
            <Tooltip disableInteractive title={t('general.close')}>
              <IconButton
                color='inherit'
                aria-label='close drawer'
                onClick={handleCloseDrawer}
                edge='start'
                size='large'>
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Typography variant='h6' noWrap>
              {selectedCampaign ? selectedCampaign.name : ''}
            </Typography>
            {isParentCampaign(selectedCampaign) && !!selectedCampaign?.published === false && (
              <Tooltip disableInteractive title={t('tours.runTour')}>
                <IconButton
                  onClick={() => forceRunTour(Tour.editCampaign)}
                  className={classes.infoIcon}
                  size='large'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            {isSubCampaign(selectedCampaign) && !!selectedCampaign?.accepted === false && (
              <Tooltip disableInteractive title={t('tours.runTour')}>
                <IconButton
                  onClick={() => forceRunTour(Tour.editSubCampaign)}
                  className={classes.infoIcon}
                  size='large'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
          {isParentCampaign(selectedCampaign) && selectedCampaign?.published && (
            <Typography variant='caption' color='primary' component='div' align='center'>
              {t('campaigns.editCampaign.publishedHelper')}
            </Typography>
          )}
          <div>
            {selectedCampaign && <EditCampaignButtons campaign={selectedCampaign} />}
            <EditCampaignForm
              campaign={selectedCampaign}
              initialValues={
                selectedCampaign ? getCampaignInitialValues(selectedCampaign) : undefined
              }
            />
          </div>
        </div>
      </SwipeableDrawer>
    </Fragment>
  )
}

const mapStateToProps = (state: StateInterface): StateProps => ({
  allCampaigns: selectCampaignsAsArray(state),
  selectedCampaign: selectSelectedCampaign(state),
  pathCampaignId: selectQueryParamFromSearch('campaignId')(state),
  campaignCustomer: selectRetailOrPharmacyCustomer(state),
  environmentType: selectCurrentEnvironmentType(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  deselectCampaign: () => dispatch(deselectCampaign()),
  createCampaign: bindSubmitActionToPromise(dispatch, createCampaign),
  closeDialog: (dialogId: string) => dispatch(closeDialog(dialogId)),
  forceRunTour: (tour: Tour) => dispatch(forceRunTour(tour))
})

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns)
