import { LinearProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import fi from 'date-fns/locale/fi'
import enGB from 'date-fns/locale/en-GB'
import React, { useState } from 'react'
import { Calendar, dateFnsLocalizer, View } from 'react-big-calendar'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelItemPriority, LogicalOperator } from '@seesignage/seesignage-utils'
import { openChannelsDialog, openEditChannelItem } from '../../../actions/channels'
import Container from '../../../components/Container'
import PageTitle from '../../../components/PageTitle'
import {
  selectChannelById,
  selectSelectedCalendarEvent,
  selectChannelItemsByChannelIdAsArray,
  selectIsListChannelItemsLoading,
  selectIsGetChannelLoading
} from '../../../selectors/channels'
import { selectPlaylists } from '../../../selectors/playlists'
import { selectContentIdFromPathname } from '../../../selectors/routing'
import {
  convertChannelItemsToEvents,
  eventPropGetter,
  getRecurringRuleFormInitialValue,
  onSelectSlot
} from '../../../utils/channels'
import Dialog from '../../Dialog'
import CreateChannelItemForm from '../Forms/CreateChannelItemForm'
import { BreadcrumbContentType } from '../../../types/breadcrumbs'
import { sortByPriority } from '../../../utils/sorting'
import { forceRunTour } from '../../../actions/tours'
import { Tour } from '../../../types/tours'
import { selectCampaigns } from '../../../selectors/campaigns'
import { validateDocumentWriteAccess } from '../../../utils/permissions'
import { selectUser } from '../../../selectors/users'
import { SortDirection } from '../../../types/sortings'
import { OpenChannelsDialogActionPayload } from '../../../types/actions'
import EditChannelToolbar from './EditChannelToolbar'
import Event from './Event'
import EditChannelItemDrawer from './EditChannelItemDrawer/EditChannelItemDrawer'
import CalendarLegend from './CalendarLegend'

const locales = {
  fi: fi,
  'en-GB': enGB
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
})

const formats = {
  timeGutterFormat: 'HH:mm'
}

const useStyles = makeStyles({
  calendarContainer: {
    height: '72vh',
    margin: 10
  }
})

const EditChannel = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const [view, setView] = useState<View>('month')
  const dispatch = useDispatch()

  const isGetChannelLoading = useSelector(selectIsGetChannelLoading)
  const isListChannelItemsLoading = useSelector(selectIsListChannelItemsLoading)
  const channelId = useSelector(selectContentIdFromPathname)
  const channel = useSelector(selectChannelById(channelId))
  const channelItems = useSelector(selectChannelItemsByChannelIdAsArray(channelId))
  const playlists = useSelector(selectPlaylists)
  const campaigns = useSelector(selectCampaigns)
  const user = useSelector(selectUser)
  const events = channel
    ? convertChannelItemsToEvents(channelItems, playlists, campaigns).sort(
        sortByPriority(SortDirection.desc)
      )
    : []
  const selectedCalendarEvent = useSelector(selectSelectedCalendarEvent)
  const hasWriteAccess = channel && user ? validateDocumentWriteAccess(channel, user) : false

  if (!channel) {
    return <LinearProgress />
  }

  const { name, channelId: id, syncPlay } = channel

  return (
    <Container>
      <PageTitle contentType={BreadcrumbContentType.channels} contentId={id} contentName={name} />
      <EditChannelToolbar
        forceRunTour={(tour: Tour) => dispatch(forceRunTour(tour))}
        syncPlay={syncPlay}
        hasWriteAccess={hasWriteAccess}
      />
      {(isGetChannelLoading || isListChannelItemsLoading) && <LinearProgress />}
      <div className={classes.calendarContainer} id='channel-calendar'>
        <Calendar
          selectable={hasWriteAccess}
          // Show truncated events in an overlay when you click the "+_x_ more" link.
          popup
          step={15}
          formats={formats}
          localizer={localizer}
          events={events}
          defaultView='month'
          scrollToTime={new Date()}
          defaultDate={new Date()}
          eventPropGetter={event => eventPropGetter(event)}
          onSelectEvent={event => dispatch(openEditChannelItem({ event }))}
          onSelectSlot={event =>
            onSelectSlot(
              event,
              events,
              view,
              (params: OpenChannelsDialogActionPayload) => dispatch(openChannelsDialog(params)),
              t
            )
          }
          onView={view => setView(view)}
          views={{ month: true, week: true, day: true }}
          components={
            {
              event: Event
            } as any
          }
          messages={{
            month: t('calendar.month'),
            week: t('calendar.week'),
            day: t('calendar.day'),
            today: t('calendar.today'),
            agenda: t('calendar.agenda'),
            next: t('calendar.next'),
            previous: t('calendar.previous'),
            event: t('calendar.event'),
            time: t('calendar.time'),
            date: t('calendar.date'),
            showMore: count => t('calendar.more', { count })
          }}
        />
      </div>
      <CalendarLegend />
      <Dialog
        dialogId='createChannelItem'
        title={t('channels.actions.createChannelItem')}
        noOpenDialogButton
        Content={
          <CreateChannelItemForm
            form='CreateChannelItemForm'
            submitButtonLabel={t('general.save')}
            dialogId='createChannelItem'
            initialValues={{
              channelItem: {
                start: selectedCalendarEvent ? selectedCalendarEvent.start : undefined,
                end: selectedCalendarEvent ? selectedCalendarEvent.end : undefined,
                recurringRule: getRecurringRuleFormInitialValue(selectedCalendarEvent),
                priority: ChannelItemPriority.low,
                conditions: {
                  operator: LogicalOperator.or
                }
              },
              playlist: {
                defaultInterval: channel.defaultPlaylistInterval || 7,
                syncPlay
              },
              isNewPlaylist: false
            }}
          />
        }
      />
      <EditChannelItemDrawer selectedCalendarEvent={selectedCalendarEvent} />
    </Container>
  )
}

export default EditChannel
