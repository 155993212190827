import {
  AutocompleteOption,
  EnvironmentFeatures,
  EnvironmentPricing,
  Environment,
  EnvironmentUserRole,
  TemplateType
} from '@seesignage/seesignage-utils'

export interface EnvironmentUser extends InitialEnvironmentUser {
  sub: string
  permissions?: Permissions
}

export interface SubEnvironmentsUI {
  [environmentId: string]: Environment
}

export interface EnvironmentUI extends Environment {
  subEnvironmentsUI: SubEnvironmentsUI
}

export interface InitialEnvironmentUser {
  role: EnvironmentUserRole
  email: string
}
export interface CreateEnvironmentFormData {
  name: string
  displayName: string
  features?: EnvironmentFeatures | null
  pricing?: EnvironmentPricing | null
  colors?: string[] | null
  parentColors?: string[] | null
  notes?: string
}
export interface AddUsersToEnvironmentFormDataBeforeValidation {
  users: (
    | {
        role?: EnvironmentUserRole
        email?: string
      }
    | undefined
  )[]
}
export interface AddUsersToEnvironmentFormData {
  users: InitialEnvironmentUser[]
}

export interface EditEnvironmentPermissionsFormData {
  environmentId: string
  role: EnvironmentUserRole
  sub: string
  permissions: PermissionsFormData
}

export interface EditEnvironmentFormData {
  name: string
  displayName: string
  features?: EnvironmentFeatures
  pricing?: EnvironmentPricing
  colors?: string[]
  notes?: string
}

export interface EditSubEnvironmentsFormData {
  environmentId: string
  selectedSubEnvironments: EnvironmentListItemView[]
}

export interface EditEnvironmentPermissionsRequestBody {
  role: EnvironmentUserRole
  sub: string
  permissions: {
    lists?: {
      read?: boolean
      write?: boolean
    }
    templates?: {
      read?: boolean
      write?: boolean
    }
    tags: string[] | null
  }
}

interface PermissionsFormData {
  lists?: {
    read?: boolean
    write?: boolean
  }
  templates?: {
    read?: boolean
    write?: boolean
  }
  tags?: AutocompleteOption[]
}

export interface GetEnvironment {
  environment: Environment
  templateTypes: TemplateType[]
}

export interface EnvironmentListItemView {
  name: string
  displayName?: string
  environmentId: string
}

export interface EnvironmentNames {
  name: string
  displayName?: string
}

/**
 * Additional settings also environment admins can modify for Environment
 */
export interface AdditionalEnvironmentSettingsFormData {
  name: string
  displayName: string
  tags: AutocompleteOption[]
  parentTags?: AutocompleteOption[]
}

export enum EnvironmentType {
  hyper = 'hyper',
  sub = 'sub',
  normal = 'normal'
}
