import { ChannelItem, ChannelItemFormData } from '@seesignage/seesignage-utils'
import { API } from 'aws-amplify'

const listChannelItems = async (environmentId: string, channelId: string) => {
  const items: ChannelItem[] = await API.get(
    'channels',
    `/environments/${environmentId}/channels/${channelId}/items`,
    {}
  )
  return items
}

const createChannelItem = async (
  environmentId: string,
  channelId: string,
  itemFormData: ChannelItemFormData
) => {
  const item: ChannelItem = await API.post(
    'channels',
    `/environments/${environmentId}/channels/${channelId}/items`,
    { body: itemFormData }
  )
  return item
}

const updateChannelItem = async (
  environmentId: string,
  channelId: string,
  itemId: string,
  itemFormData: ChannelItemFormData
) => {
  const item: ChannelItem = await API.put(
    'channels',
    `/environments/${environmentId}/channels/${channelId}/items/${itemId}`,
    { body: itemFormData }
  )
  return item
}

const deleteChannelItem = async (environmentId: string, channelId: string, itemId: string) => {
  await API.del(
    'channels',
    `/environments/${environmentId}/channels/${channelId}/items/${itemId}`,
    {}
  )
}

export default {
  listChannelItems,
  createChannelItem,
  updateChannelItem,
  deleteChannelItem
}
