import React from 'react'
import classNames from 'classnames'
import PreviewIcon from 'mdi-react/MoviePlayIcon'
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Tooltip,
  Typography,
  IconButton,
  CardMedia,
  Badge
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import dateFormat from 'dateformat'
import { useTranslation } from 'react-i18next'
import SettingsIcon from 'mdi-react/SettingsIcon'
import LockIcon from 'mdi-react/LockIcon'
import ContentCopyIcon from 'mdi-react/ContentCopyIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { TFunction } from 'i18next'
import { PlaylistUI, PlaylistItemType } from '@seesignage/seesignage-utils'
import FileImageIcon from 'mdi-react/FileImageIcon'
import SyncIcon from 'mdi-react/RayStartVertexEndIcon'
import IntegrationIcon from 'mdi-react/AllInclusiveIcon'
import Dialog from '../Dialog'
import { Navigate, CloseDialog, CreatePreviewSessionParams } from '../../types/actions'
import { UserInterface } from '../../types/users'
import TruncatedChip from '../../components/Chips'
import LightTooltip from '../../components/Tooltip/LightTooltip'
import { getPlaylistDurationText } from '../../utils/playlists'
import colors from '../../styles/common/colors'
import { validatePlaylistWriteAccess } from '../../utils/permissions'
import { truncateText } from '../../utils/text'
import CreatePlaylistForm from './Forms/CreatePlaylistForm'
import defaultCardImage from './playlistCard.jpg'
import DeleteConfirmation from './Confimation'

const useStyles = makeStyles(theme => ({
  card: {
    width: 250,
    height: 355,
    display: 'inline-block' as any,
    verticalAlign: 'middle' as any,
    margin: '0px 5px 15px'
  },
  '@keyframes recentChanges': {
    '0%': {
      opacity: 0.1
    },
    '100%': {
      opacity: 1
    }
  },
  cardWithRecentChanges: {
    boxShadow: `${theme.palette.success.light} 0px 4px 8px 1px, ${theme.palette.success.light} 0px 2px 2px 1px, ${theme.palette.success.light} 0px 3px 1px -2px`,
    animation: `$recentChanges 1000ms ${theme.transitions.easing.easeInOut}`
  },
  cardContent: {
    display: 'inline-block' as any,
    cursor: 'pointer' as any,
    userSelect: 'none' as any,
    '-moz-user-select': 'none' as any,
    '-ms-user-select': 'none' as any,
    '-webkit-user-select': 'none' as any,
    height: 142,
    width: '100%',
    overflowY: 'auto' as any,
    overflowX: 'hidden' as any,
    position: 'relative' as any,
    boxSizing: 'border-box' as any
  },
  actions: {
    float: 'right' as any
  },
  link: {
    textDecoration: 'none' as any
  },
  titleText: {
    width: '80%',
    padding: '4px',
    float: 'left' as any,
    textAlign: 'left' as any,
    fontSize: '1.25rem'
  },
  cardMedia: {
    height: 140,
    width: '100%',
    objectFit: 'contain'
  },
  playlistIcons: {
    position: 'absolute' as any,
    right: 8,
    top: 17,
    opacity: 0.7
  },
  playlistIcon: { display: 'block' }
}))

interface RenderActionsProps {
  classes: Record<string, string>
  playlist: PlaylistUI
  updatePlaylist: (formData: any) => void
  deletePlaylist: (playlistId: string) => void
  closeDialog: CloseDialog
  user: UserInterface
  copyPlaylist: (playlistId: string) => void
  createPreviewSession: (createPreviewSessionParams: CreatePreviewSessionParams) => void
  t: TFunction
  isWriteDisabled: boolean
}

const renderActions = ({
  classes,
  playlist: { permissionTags, playlistId, environmentId, name, defaultInterval, syncPlay, items },
  updatePlaylist,
  deletePlaylist,
  closeDialog,
  user,
  copyPlaylist,
  createPreviewSession,
  isWriteDisabled,
  t
}: RenderActionsProps) => (
  <CardActions className={classes.actions}>
    <Tooltip
      disableInteractive
      title={t(`playlists.actions.${isWriteDisabled ? 'copyNoPermission' : 'copy'}`) as string}>
      <span>
        <IconButton
          onClick={() => copyPlaylist(playlistId)}
          disabled={isWriteDisabled}
          size='large'>
          <ContentCopyIcon />
        </IconButton>
      </span>
    </Tooltip>
    <Dialog
      dialogId={`UpdatePlaylistDialog.${playlistId}`}
      title={`${t('playlists.actions.settings')} (${name})`}
      ButtonIcon={SettingsIcon}
      tooltipTitle={
        isWriteDisabled
          ? t('playlists.actions.settingsNoPermission')
          : t('playlists.actions.settings')
      }
      buttonDisabled={isWriteDisabled}
      Content={
        <CreatePlaylistForm
          dialogId={`UpdatePlaylistDialog.${playlistId}`}
          disableSyncPlay={items.some(item => item.type === PlaylistItemType.revolver)}
          submitButtonLabel={t('playlists.actions.update')}
          submitAction={updatePlaylist}
          closeDialog={closeDialog}
          user={user}
          initialValues={{
            name,
            defaultInterval,
            playlistId,
            syncPlay,
            permissionTags:
              permissionTags &&
              permissionTags.map(tag => ({
                label: tag,
                value: tag
              }))
          }}
        />
      }
    />
    <Dialog
      dialogId={`removePlaylistDialog.${playlistId}`}
      title={`${t('playlists.actions.removeList')} (${name})`}
      ButtonIcon={DeleteIcon}
      tooltipTitle={
        isWriteDisabled
          ? t('playlists.actions.removeListNoPermission')
          : t('playlists.actions.removeList')
      }
      buttonDisabled={isWriteDisabled}
      Content={
        <DeleteConfirmation
          environmentId={environmentId}
          closeDialog={closeDialog}
          deletePlaylist={deletePlaylist}
          playlistId={playlistId}
          t={t}
        />
      }
    />
    <Tooltip disableInteractive title={t('playlists.editPlaylist.previewPlaylist')}>
      <IconButton onClick={() => createPreviewSession({ playlistId })} size='large'>
        <PreviewIcon />
      </IconButton>
    </Tooltip>
  </CardActions>
)

/**
 * Check if playlist has recent changes
 * modified in last 1 minute
 */
const hasRecentChanges = (createdAt: number, updatedAt?: number) => {
  const limit = Date.now() - 1000 * 30 * 1
  return createdAt > limit || (updatedAt && updatedAt > limit)
}

interface PlaylistCardProps {
  playlist: PlaylistUI
  navigate: Navigate
  updatePlaylist: (formData: any) => void
  deletePlaylist: (playlistId: string) => void
  closeDialog: CloseDialog
  copyPlaylist: (playlistId: string) => void
  createPreviewSession: (createPreviewSessionParams: CreatePreviewSessionParams) => void
  permissionTags?: string[]
  user: UserInterface
}

const PlaylistCard: React.FC<PlaylistCardProps> = ({
  playlist,
  navigate,
  updatePlaylist,
  deletePlaylist,
  closeDialog,
  copyPlaylist,
  createPreviewSession,
  user
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  const {
    playlistId,
    totalDuration,
    environmentId,
    name,
    createdAt,
    updatedAt,
    defaultInterval,
    items,
    integration,
    permissionTags,
    thumbnailUrl,
    syncPlay
  } = playlist
  const isWriteDisabled = !validatePlaylistWriteAccess(playlist, user)

  return (
    <Card
      className={classNames({
        [classes.card]: true,
        [classes.cardWithRecentChanges]: hasRecentChanges(createdAt, updatedAt)
      })}>
      <LightTooltip title={t('playlists.actions.edit')}>
        <CardActionArea
          onClick={() =>
            navigate(`/environments/${environmentId}/playlists?playlistId=${playlistId}`)
          }>
          <CardMedia
            component='img'
            className={classes.cardMedia}
            image={thumbnailUrl || defaultCardImage}
          />
          <CardContent className={classes.cardContent}>
            <div className={classes.playlistIcons}>
              <Tooltip
                disableInteractive
                title={
                  items.length > 0
                    ? (t('playlists.card.containsCount', { count: items.length }) as string)
                    : (t('playlists.card.empty') as string)
                }>
                <Badge
                  className={classes.playlistIcon}
                  badgeContent={items.length}
                  showZero
                  color='default'>
                  <FileImageIcon />
                </Badge>
              </Tooltip>
              {syncPlay && (
                <Tooltip disableInteractive title={t('playlists.card.syncPlaylist')}>
                  <span className={classes.playlistIcon}>
                    <SyncIcon color={colors.seesignageColor} />
                  </span>
                </Tooltip>
              )}
              {integration && (
                <Tooltip disableInteractive title={t('playlists.card.integrationPlaylist')}>
                  <span className={classes.playlistIcon}>
                    <IntegrationIcon color={colors.seesignageColor} />
                  </span>
                </Tooltip>
              )}
            </div>
            <div className={classes.titleText}>{truncateText(name, 60)}</div>
            <Typography variant='caption' display='block'>
              {t('playlists.card.updated', {
                updatedAt: updatedAt ? dateFormat(updatedAt, 'dd.mm.yyyy HH:MM') : '-'
              })}
            </Typography>
            <Typography variant='caption' display='block'>
              {t('playlists.card.defaultInterval', { defaultInterval })}
            </Typography>
            {totalDuration && (
              <Typography variant='caption' display='block'>
                {getPlaylistDurationText(totalDuration)}
              </Typography>
            )}
            {permissionTags &&
              permissionTags.map(tag => (
                <TruncatedChip truncateLimit={30} Icon={LockIcon} key={tag} tag={tag} />
              ))}
          </CardContent>
        </CardActionArea>
      </LightTooltip>
      {renderActions({
        classes,
        playlist,
        updatePlaylist,
        deletePlaylist,
        closeDialog,
        user,
        copyPlaylist,
        createPreviewSession,
        isWriteDisabled,
        t
      })}
    </Card>
  )
}
export default PlaylistCard
