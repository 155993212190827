import React, { Fragment, useState } from 'react'
import { Typography, IconButton, SwipeableDrawer, Toolbar, Tooltip } from '@mui/material'
import { isChannelItemPlaylist, isChannelItemCampaign } from '@seesignage/seesignage-utils'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from 'mdi-react/CloseIcon'
import { useTranslation } from 'react-i18next'
import { CalendarEvent } from '../../../../types/channels'
import { selectContentIdFromPathname } from '../../../../selectors/routing'
import { selectChannelItemById, selectIsEditChannelItemOpen } from '../../../../selectors/channels'
import { selectIsPlaylistModified } from '../../../../selectors/playlists'
import PlaylistUnsavedChanges from '../../../Playlists/Notification/PlaylistUnsavedChanges'
import { deselectPlaylist } from '../../../../actions/playlists'
import { deselectCampaign } from '../../../../actions/campaigns'
import { closeEditChannelItem } from '../../../../actions/channels'
import EditChannelItemCampaign from './EditChannelItemCampaign'
import EditChannelItemPlaylist from './EditChannelItemPlaylist'

interface OwnProps {
  selectedCalendarEvent?: CalendarEvent
}

const EditChannelItemDrawer: React.FC<OwnProps> = ({ selectedCalendarEvent }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [alertOpen, setAlertOpen] = useState(false)
  const isEditChannelItemOpen = useSelector(selectIsEditChannelItemOpen)
  const channelId = useSelector(selectContentIdFromPathname)

  const selectedChannelItem = useSelector(
    selectChannelItemById(channelId, selectedCalendarEvent?.itemId)
  )
  const isPlaylistModified = useSelector(selectIsPlaylistModified)

  const closeEditPlaylist = () => {
    dispatch(deselectPlaylist())
    dispatch(deselectCampaign())
    dispatch(closeEditChannelItem())
  }

  const handleCloseDrawer = () => {
    if (!isPlaylistModified) {
      closeEditPlaylist()
      setAlertOpen(false)
    } else {
      setAlertOpen(true)
    }
  }

  return (
    <SwipeableDrawer
      anchor='right'
      open={isEditChannelItemOpen}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onOpen={() => {}}
      onClose={handleCloseDrawer}
      disableSwipeToOpen>
      <Fragment>
        <Toolbar>
          <Tooltip disableInteractive title={t('general.close')}>
            <IconButton
              color='inherit'
              aria-label='close drawer'
              onClick={handleCloseDrawer}
              edge='start'
              size='large'>
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography variant='h6' noWrap>
            {selectedCalendarEvent ? selectedCalendarEvent.title : ''}
          </Typography>
        </Toolbar>
        {selectedCalendarEvent && (
          <Fragment>
            {// render only when open, otherwise form field might not be reseted
            isChannelItemPlaylist(selectedChannelItem) && isEditChannelItemOpen && (
              <Fragment>
                <EditChannelItemPlaylist
                  selectedCalendarEvent={selectedCalendarEvent}
                  selectedChannelItem={selectedChannelItem}
                />
                <PlaylistUnsavedChanges
                  open={alertOpen}
                  closeNotification={() => setAlertOpen(false)}
                />
              </Fragment>
            )}
            {isChannelItemCampaign(selectedChannelItem) && selectedCalendarEvent?.userHasAccess && (
              <EditChannelItemCampaign />
            )}
          </Fragment>
        )}
      </Fragment>
    </SwipeableDrawer>
  )
}

export default EditChannelItemDrawer
