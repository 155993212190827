import { createAction, createAsyncActions } from './utils'

const reducer = 'CHANNELS'

const resetChannels = createAction(reducer, 'RESET')

const openEditChannelItem = createAction(reducer, 'OPEN_EDIT_CHANNEL_ITEM')
const closeEditChannelItem = createAction(reducer, 'CLOSE_EDIT_CHANNEL_ITEM')

const {
  action: createChannel,
  actionSuccess: createChannelSuccess,
  actionFail: createChannelFail
} = createAsyncActions(reducer, 'CREATE')

const {
  action: createChannelItem,
  actionSuccess: createChannelItemSuccess,
  actionFail: createChannelItemFail
} = createAsyncActions(reducer, 'CREATE_ITEM')

const {
  action: updateChannelItem,
  actionSuccess: updateChannelItemSuccess,
  actionFail: updateChannelItemFail
} = createAsyncActions(reducer, 'UPDATE_ITEM')

const {
  action: deleteChannelItem,
  actionSuccess: deleteChannelItemSuccess,
  actionFail: deleteChannelItemFail
} = createAsyncActions(reducer, 'DELETE_ITEM')

const {
  action: listChannelItems,
  actionSuccess: listChannelItemsSuccess,
  actionFail: listChannelItemsFail
} = createAsyncActions(reducer, 'LIST_ITEMS')

const {
  action: updateChannel,
  actionSuccess: updateChannelSuccess,
  actionFail: updateChannelFail
} = createAsyncActions(reducer, 'UPDATE')

const {
  action: deleteChannel,
  actionSuccess: deleteChannelSuccess,
  actionFail: deleteChannelFail
} = createAsyncActions(reducer, 'DELETE')

const {
  action: listChannels,
  actionSuccess: listChannelsSuccess,
  actionFail: listChannelsFail
} = createAsyncActions(reducer, 'LIST')

const {
  action: getChannel,
  actionSuccess: getChannelSuccess,
  actionFail: getChannelFail
} = createAsyncActions(reducer, 'GET')

const openChannelsDialog = createAction(reducer, 'OPEN_CHANNELS_DIALOG')

export {
  resetChannels,
  createChannel,
  createChannelSuccess,
  createChannelFail,
  createChannelItem,
  createChannelItemSuccess,
  createChannelItemFail,
  updateChannelItem,
  updateChannelItemSuccess,
  updateChannelItemFail,
  listChannelItems,
  listChannelItemsSuccess,
  listChannelItemsFail,
  updateChannel,
  updateChannelSuccess,
  updateChannelFail,
  deleteChannelItem,
  deleteChannelItemSuccess,
  deleteChannelItemFail,
  deleteChannel,
  deleteChannelSuccess,
  deleteChannelFail,
  listChannels,
  listChannelsSuccess,
  listChannelsFail,
  getChannel,
  getChannelSuccess,
  getChannelFail,
  openChannelsDialog,
  openEditChannelItem,
  closeEditChannelItem
}
