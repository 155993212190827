import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  LinearProgress,
  Theme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  CircularProgress
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import EmailIcon from 'mdi-react/EmailIcon'
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon'
import EmailOpenIcon from 'mdi-react/EmailOpenIcon'
import AccountIcon from 'mdi-react/AccountCircleIcon'
import PhoneIcon from 'mdi-react/PhoneIcon'
import AccountCheckIcon from 'mdi-react/AccountCheckIcon'
import { useTranslation } from 'react-i18next'
import AccountEditIcon from 'mdi-react/AccountEditIcon'
import LockIcon from 'mdi-react/LockIcon'
import {
  updateUserInfo as updateUserInfoAction,
  changePassword as changePasswordAction,
  deleteUser as deleteUserAction,
  subscribeUserToNewsLetter as subscribeUserToNewsLetterAction
} from '../../actions/users'
import { bindSubmitActionToPromise } from '../../utils/forms'
import Dialog from '../Dialog'
import { closeDialog as closeDialogAction } from '../../actions/dialogs'
import { isAdminRole } from '../../utils/permissions'
import ChangeLanguageForm from '../../components/ChangeLanguageForm/ChangeLanguageForm'
import { selectListEnvironmentsIsLoading } from '../../selectors/environments'
import { BreadcrumbContentType } from '../../types/breadcrumbs'
import PageTitle from '../../components/PageTitle'
import { selectUser, selectUserIsAuthenticated } from '../../selectors/users'
import EditProfileInfoForm from './EditProfileInfoForm'
import ChangePasswordForm from './ChangePasswordForm'

const styles = (theme: Theme) => ({
  form: {
    margin: '10px auto',
    maxWidth: '320px',
    textAlign: 'center' as 'center'
  },
  button: {
    marginTop: theme.spacing(2),
    textDecoration: 'none' as 'none'
  },
  languageForm: {
    width: 200,
    margin: theme.spacing(2)
  }
})

const useStyles = makeStyles(styles)

const Profile: React.FC = () => {
  const classes = useStyles()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false)
  const updateUserInfo = bindSubmitActionToPromise(dispatch, updateUserInfoAction)
  const changePassword = bindSubmitActionToPromise(dispatch, changePasswordAction)
  const deleteUser = () => dispatch(deleteUserAction())
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))
  const subscribeUserToNewsLetter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoadingSubscription(true)
    bindSubmitActionToPromise(
      dispatch,
      subscribeUserToNewsLetterAction
    )({ subscriptions: { newsLetter: event.target.checked } }).finally(() =>
      setIsLoadingSubscription(false)
    )
  }

  const user = useSelector(selectUser)
  const isAuthenticated = useSelector(selectUserIsAuthenticated)
  const environmentsAreLoading = useSelector(selectListEnvironmentsIsLoading)

  if (isAuthenticated && user) {
    const {
      attributes: { email, name, phone_number: phone },
      role,
      subscriptions
    } = user
    const profileInfo = {
      name,
      // eslint-disable-next-line @typescript-eslint/camelcase
      phone_number: phone
    }
    return (
      <Fragment>
        <PageTitle contentType={BreadcrumbContentType.profile} />
        {environmentsAreLoading ? (
          <LinearProgress />
        ) : (
          <div className={classes.form}>
            <List component='nav'>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary={email} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AccountIcon />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary={phone} />
              </ListItem>
              <ListItem
                secondaryAction={
                  isLoadingSubscription ? (
                    <CircularProgress color='primary' size={20} />
                  ) : (
                    <Checkbox
                      edge='end'
                      disabled={isLoadingSubscription}
                      checked={Boolean(subscriptions?.newsLetter)}
                      onChange={subscribeUserToNewsLetter}
                    />
                  )
                }>
                <ListItemIcon>
                  <EmailOpenIcon />
                </ListItemIcon>
                <ListItemText primary={t('subscriptions.title')} />
              </ListItem>
              {isAdminRole(role) && (
                <ListItem>
                  <ListItemIcon>
                    <AccountCheckIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('profile.systemAdmin')} />
                </ListItem>
              )}
            </List>
            <Divider />
            <ChangeLanguageForm />
            <Divider />

            <Dialog
              buttonClassName={classes.button}
              buttonFullWidth
              ButtonIcon={AccountEditIcon}
              buttonLabel={t('editProfile.edit')}
              dialogId='EditAccountDialog'
              title={t('editProfile.edit')}
              color='primary'
              Content={
                <EditProfileInfoForm
                  initialValues={profileInfo}
                  updateUserInfo={updateUserInfo}
                  closeDialog={() => closeDialog('EditAccountDialog')}
                  t={t}
                />
              }
            />
            <Dialog
              buttonClassName={classes.button}
              buttonFullWidth
              ButtonIcon={LockIcon}
              buttonLabel={t('changePw.change')}
              dialogId='ChangePasswordDialog'
              title={t('changePw.change')}
              color='primary'
              Content={
                <ChangePasswordForm
                  changePassword={changePassword}
                  closeDialog={() => closeDialog('EditAccountDialog')}
                  t={t}
                />
              }
            />

            <Dialog
              buttonClassName={classes.button}
              buttonFullWidth
              ButtonIcon={DeleteForeverIcon}
              buttonLabel={t('profile.delete')}
              dialogId='RemoveAccountDialog'
              title={t('profile.delete')}
              color='secondary'
              Content={
                <Fragment>
                  <DialogContent>
                    <DialogContentText>{t('profile.confirmation')}</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => closeDialog('RemoveAccountDialog')} color='primary'>
                      {t('general.disagree')}
                    </Button>
                    <Button onClick={() => deleteUser()} color='secondary' autoFocus>
                      {t('general.agree')}
                    </Button>
                  </DialogActions>
                </Fragment>
              }
            />
          </div>
        )}
      </Fragment>
    )
  }
  return <Fragment />
}

export default Profile
